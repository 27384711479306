// AuthContext.js
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import api from '../classes/API'; // Import your API class
import '../utils/i18n.js'; // Import the i18n configuration
import { AppRoutes } from '../config/AppModes.ts';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authCode, setAuthCode] = useState('');
    const [email, setEmail] = useState('');
    const [userProfile, setUserProfile] = useState(null);
    const [token, setToken] = useState(null);
    const [tokenVerificationCompleted, setTokenVerificationCompleted] = useState(false);

    const hasVerifiedToken = useRef(false); // Track token verification

    useEffect(() => {
        const savedToken = localStorage.getItem('authToken');
        if (savedToken && !hasVerifiedToken.current) {
            hasVerifiedToken.current = true; // Set flag to true
            verifyToken(savedToken);
        }
        if (!savedToken) {
            hasVerifiedToken.current = true;
            setTokenVerificationCompleted(true);
        }
    }, []);

    const verifyToken = async (authToken) => {
        let isAuth = false;
        if (!authToken) return;
        if (token) return;

        try {
            //console.log(`Verifying token: ${authToken}`);
            const response = await api.verifyToken({ token: authToken });

            if (response.status === 401) {
                console.log('Unauthorized token');
                logout();
                return;
            }

            const data = await response.json();

            if (data.valid) {
                setIsAuthenticated(true);
                setToken(authToken);
                isAuth = true;
                setUserProfile(data.user); // Assuming your API returns user profile data
                setTokenVerificationCompleted(true);
            } else {
                console.log('Error verifying token');
            }
        } catch (error) {
            console.error('Error verifying token:', error);
        } finally {
            console.log('Verification completed. Authenticated: ', isAuth);
        }
    };

    const login = (token) => {
        // Perform login
        // Store JWT token in local storage
        localStorage.setItem('authToken', token);
        setToken(token);
        setIsAuthenticated(true);
    }

    const logout = () => {
        setIsAuthenticated(false);
        setUserProfile(null);
        setToken(null);
        localStorage.removeItem('authToken');
    }

    const updateUserProfile = (profile) => {
        setUserProfile(profile);
    };

    const sendCode = async (userEmail) => {
        setEmail(userEmail);
        return await api.apiRequestInstantLogin(userEmail);
    };

    const verifyCode = async (inputCode) => {
        try {
            const tokenResponse = await api.apiInstantLogin(email, inputCode);
            const tokenData = await tokenResponse.json();
            if (tokenData.error) {
                return false;
            } else {
                login(tokenData.token);
                setAuthCode('');
                updateUserProfile(tokenData.user);
                return true;
            }
        } catch (err) {
            return false;
        }
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated, login, logout, sendCode, verifyCode,
            email,
            authCode,
            userProfile, updateUserProfile,
            token, setToken, tokenVerificationCompleted,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export const useAuthRedirect = (setDirection, useDelayedNavigation) => {
    const setDelayedNavigation = useDelayedNavigation();
    const { isAuthenticated, tokenVerificationCompleted } = useAuth();
    const hasRedirected = useRef(false);

    useEffect(() => {
        //console.log('Need redirect? isAuthenticated:', isAuthenticated, 'tokenVerificationCompleted:', tokenVerificationCompleted);
        if (!isAuthenticated && !hasRedirected.current && tokenVerificationCompleted) {
            hasRedirected.current = true;
            setDelayedNavigation(() => setDirection('backward'), AppRoutes.ROUTE_WELCOME.path);
        }
    }, [isAuthenticated, setDelayedNavigation, setDirection, tokenVerificationCompleted]);
};