import React, { useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Slide, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SlideUpTransition = React.forwardRef(function SlideUpTransition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FileUploadDialog({ open, setOpen, onFileUpload }) {
    const { t } = useTranslation();
    const [uploadOptionSelected, setUploadOptionSelected] = useState(false);
    const fileInputRef = useRef(null);

    const handleOptionSelect = (option) => {
        if (option === 'uploadFile' || option === 'takePhoto') {
            // Trigger file input click for file upload or taking a photo
            const inputElement = fileInputRef.current;
            if (option === 'takePhoto') {
                inputElement.setAttribute('capture', 'environment'); // Use environment camera (back camera)
                inputElement.setAttribute('accept', 'image/*'); // Accept only images
            } else {
                inputElement.removeAttribute('capture');
                inputElement.setAttribute('accept', '*/*'); // Accept all file types
            }
            inputElement.click();
        } else {
            setUploadOptionSelected(option);
        }
    };

    const handleFileChange = (event) => {
        const files = event.target.files; // array of files
        console.log(files);
        if (files) {
            onFileUpload(files);
        }
        setOpen(false);
        // Reset the input value to allow the same file to be selected again
        event.target.value = '';
    };

    const onClose = () => {
        setOpen(false);
        setUploadOptionSelected(false);
    }


    return (
        <>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <Dialog
                open={open}
                onClose={onClose}
                TransitionComponent={SlideUpTransition}
                keepMounted
                aria-labelledby="upload-dialog-title"
                aria-describedby="upload-dialog-description"
                PaperProps={{
                    sx: {
                        py: 4, px: 4,
                        height: 'auto',
                        borderRadius: '11px',
                        overflow: 'hidden',
                        position: 'fixed',
                        bottom: 0,
                        margin: 0,
                        width: 'auto',

                    },
                }}
            >
                <DialogContent sx={{ textAlign: 'center' }}>
                    {!uploadOptionSelected ? (
                        <>
                            <Button
                                sx={{ my: 1, minWidth: '60%' }}
                                variant="outlined"
                                onClick={() => handleOptionSelect('takePhoto')}
                            >
                                {t('uploadOptions.takePhoto')}
                            </Button>
                            <Button
                                sx={{ my: 1, minWidth: '60%' }}
                                variant="outlined"
                                onClick={() => handleOptionSelect('photoGallery')}
                            >
                                {t('uploadOptions.photoGallery')}
                            </Button>
                            <Button
                                sx={{ my: 1, minWidth: '60%' }}
                                variant="outlined"
                                onClick={() => handleOptionSelect('uploadFile')}
                            >
                                {t('uploadOptions.uploadFile')}
                            </Button>
                        </>
                    ) : (
                        <DialogContentText id="upload-dialog-description">
                            {t('confirmUpload', { option: uploadOptionSelected })}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    {uploadOptionSelected && (
                        <Button sx={{ my: 1, minWidth: '60%' }} variant="outlined" onClick={handleFileChange} color="primary">
                            {t('upload')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}
