// /src/classes/ImageGenerator.js
import api from './API.js';

class ImageGenerator {
    constructor() { }

    async readResponseStream(reader, onProgress) {

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            let data = {}
            try {
                //console.log("Event value", value);
                data = JSON.parse(value);
                //console.log("Event", data);
            }
            catch (error) {
                console.error("Error parsing JSON:", error, value);
                //throw error;
            }

            if (data.type === 'progress') {
                //console.log("Image generation progress:", data);
                onProgress(data.progress);
            } else if (data.type === 'done') {
                console.log("Image generated successfully, data:", data);
                return { documentId: data.documentId, description: data.description };
            } else if (data.type === 'error') {
                throw new Error(data.error);
            }

        }

    }

    async generateImage(data, onProgress) {
        try {
            const response = await api.generateImage(data);

            if (response.ok) {
                const reader = response.body
                    .pipeThrough(new TextDecoderStream("utf-8"))
                    .getReader();

                return await this.readResponseStream(reader, onProgress);
            } else {
                throw new Error("Failed to initiate image generation");
            }
        } catch (error) {
            console.error("Image generation error:", error);
            throw error;
        }
    }
}

export default ImageGenerator;
