import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(55, 0, 171)',
            mainHover: 'rgb(155, 0, 171)',
        },
        secondary: {
            main: 'rgb(55, 0, 171)',
            mainHover: 'rgb(155, 0, 171)',
        },
        gradient: 'linear-gradient(180deg, #6428E1 0%, #3700AB 100%)',
        // Define other colors or theme options here
    },
    typography: {
        // font family for all
        fontFamily: 'Ubuntu, Roboto, Arial, sans-serif',
        color: 'white',
        // Customize the h1 variant as an example
        h1: {
            fontSize: '2.1rem', // Example size, adjust as needed
            fontWeight: 300, // Default is 400, adjust as needed
            lineHeight: 1.2, // Example line height, adjust as needed
            margin: '0.5rem 0', // Example margin, adjust as needed
            color: 'rgb(55, 0, 171)',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.9)'
            // Add other styles as needed
        },
        h2: {
            fontSize: '1.65rem', // Example size, adjust as needed
            fontWeight: 400, // Default is 400, adjust as needed
            lineHeight: 1.4, // Example line height, adjust as needed
            margin: '0.2rem 0', // Example margin, adjust as needed
            color: 'rgb(55, 0, 171)',
            // Add other styles as needed
        },
        h3: {
            fontSize: '1.2rem', // Example size, adjust as needed
            fontWeight: 500, // Default is 400, adjust as needed
            lineHeight: 1.0, // Example line height, adjust as needed
            margin: '0.5rem 0', // Example margin, adjust as needed
            color: 'rgb(55, 0, 171)',
            // Add other styles as needed
        },
        h4: {
            fontSize: '1rem', // Example size, adjust as needed
            fontWeight: 500, // Default is 400, adjust as needed
            lineHeight: 1.0, // Example line height, adjust as needed
            margin: '0.5rem 0', // Example margin, adjust as needed
            color: 'rgb(55, 0, 171)',
            // Add other styles as needed
        },
        // You can also customize other variants here
        copyright: {
            fontSize: '0.9rem', // Example size, adjust as needed
            fontWeight: 400, // Default is 400, adjust as needed
            lineHeight: 1.2, // Example line height, adjust as needed
            margin: '0.5rem 0', // Example margin, adjust as needed
        },
        adtext: {
            fontSize: '1.2rem', // Example size, adjust as needed
            fontWeight: 400, // Default is 400, adjust as needed
            lineHeight: 1.2, // Example line height, adjust as needed
            margin: '0.5rem 0', // Example margin, adjust as needed
        },

        body1: {
            fontWeight: 400,
            fontSize: '1.2rem', // Example size, adjust as needed
            lineHeight: 1.4, // Example line height, adjust as needed
            color: '#504746',
        },
        body2: {
            fontSize: '1.05rem', // Example size, adjust as needed
            lineHeight: 1.3, // Example line height, adjust as needed
            color: '#504746',
        },
        body3: {
            fontWeight: 100,
            fontFamily: 'Ubuntu, Roboto, Arial, sans-serif',
            lineHeight: 1.3, // Example line height, adjust as needed
            fontSize: '1rem', // Example size, adjust as needed
        },

    },
    components: {
        // Customization for Button component
        MuiButton: {
            styleOverrides: {
                // Apply styles for the "contained" variant
                contained: {
                    background: 'linear-gradient(180deg, #6428E1 0%, #3700AB 100%)', // Custom background 
                    borderRadius: '100px',
                    paddingTop: "0em",
                    paddingBottom: "0em",
                    paddingLeft: "1.4em",
                    paddingRight: "1.4em",
                    minHeight: "56px",
                    textTransform: "none",
                    fontSize: "1.2rem",
                    fontWeight: "400",
                    maxHeight: "2.3em",
                    color: '#fff', // Custom text color
                    transition: 'background 1s ease, color 1s ease', // Doesn't work for some reason
                    '&:hover': {
                        background: 'linear-gradient(180deg, #7230FE 0%, #7230FE 100%)', // Custom background 
                        backgroundColor: 'rgb(114, 48, 254)', // Lighter shade for hover state
                    },
                },
                outlined: {
                    backgroundColor: 'white', // Custom background color
                    borderRadius: '100px',
                    fontSize: '1.2rem', // Example size, adjust as needed
                    textDecoration: "none",
                    textTransform: "none",
                    fontWeight: "500",
                    paddingTop: "0em",
                    paddingBottom: "0em",
                    paddingLeft: "1.4em",
                    paddingRight: "1.4em",
                    minHeight: "56px",
                    color: "rgb(55, 0, 171)",
                    border: "1px solid rgb(55, 0, 171)",
                    '&:hover': {
                        backgroundColor: "rgb(114, 48, 254)",
                        color: 'white',
                        border: "1px solid rgb(114, 48, 254)",
                    },
                },
                danger: {
                    background: 'linear-gradient(180deg, #FF0000 0%, #8B0000 100%)', // Red gradient background
                    borderRadius: '100px',
                    paddingTop: "0em",
                    paddingBottom: "0em",
                    paddingLeft: "1.4em",
                    paddingRight: "1.4em",
                    minHeight: "56px",
                    textTransform: "none",
                    fontSize: "1.2rem",
                    fontWeight: "400",
                    maxHeight: "2.3em",
                    color: '#fff', // Custom text color
                    transition: 'background 1s ease, color 1s ease', // Doesn't work for some reason
                    '&:hover': {
                        background: 'linear-gradient(180deg, #7230FE 0%, #7230FE 100%)', // Custom background 
                        backgroundColor: 'rgb(114, 48, 254)', // Lighter shade for hover state
                    },
                },
                text: {
                    borderRadius: '100px',
                    borderColor: 'black',
                    //borderStyle: 'solid',
                    //borderWidth: '0.1em',
                    paddingLeft: "1.5em",
                    paddingRight: "1.5em",
                    minHeight: "3.3em",
                }
                // You can add customization for other variants (text, outlined) here
            },
        },
        MuiTextField: {
            styleOverrides: {
                input: {
                    fontSize: '2.2rem', // Example size, adjust as needed
                    color: 'rgb(55, 0, 171)',
                },
                outlined: {
                    fontSize: '2.2rem', // Example size, adjust as needed
                    color: 'rgb(55, 0, 171)',
                },
            },
        },
    },
});

export default theme;