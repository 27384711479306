import serviceHA from '../img/services/service-health.webp';
import serviceFT from '../img/services/service-tarot.webp';
import serviceHOROSCOPE from '../img/services/service-horoscope.webp';
import serviceTALISMAN from '../img/services/service-talisman.webp';
import serviceASTROLOGY from '../img/services/service-astrology.webp';
import servicePSYCHO from '../img/services/service-psychologist.webp';
import { officeTypes } from '../config/AppModes.ts';

const officesData = [
    { type: officeTypes.HEALTH, id: 1, title: 'offices.healthAdvisor.title', description: 'offices.healthAdvisor.description', image: serviceHA },
    { type: officeTypes.TAROT, id: 2, title: 'offices.tarot.title', description: 'offices.tarot.description', image: serviceFT },
    { type: officeTypes.PSYCHO, id: 3, title: 'offices.psycho.title', description: 'offices.psycho.description', image: servicePSYCHO },
    { type: officeTypes.TALISMAN, id: 4, title: 'offices.talisman.title', description: 'offices.talisman.description', image: serviceTALISMAN },
    { type: officeTypes.ASTRAL, id: 5, title: 'offices.astrology.title', description: 'offices.astrology.description', image: serviceASTROLOGY },
    { type: officeTypes.HOROSCOPE, id: 6, title: 'offices.horoscope.title', description: 'offices.horoscope.description', image: serviceHOROSCOPE },
];

class OfficeManager {
    constructor(offices) {
        this.offices = offices;
        this.currentOffice = this.loadCurrentOfficeFromLocalStorage() || officeTypes.HEALTH;
        this.currentOfficeData = this.getOfficeByType(this.currentOffice);
        this.setCurrentOffice(this.currentOffice); // Ensure the current office is set properly


    }

    getAllOffices() {
        return this.offices;
    }

    getOfficeByType(type) {
        return this.offices.find(office => office.type === type);
    }

    getOfficeTitleByType(type) {
        const office = this.getOfficeByType(type);
        return office ? office.title : null;
    }

    getOfficeImageByType(type) {
        const office = this.getOfficeByType(type);
        return office ? office.image : null;
    }

    setCurrentOffice(type) {
        const office = this.getOfficeByType(type);
        if (office) {
            this.currentOffice = type;
            this.currentOfficeData = office;
            this.saveCurrentOfficeToLocalStorage(type); // Save to local storage on change
        } else {
            throw new Error(`Office type ${type} does not exist`);
        }
    }

    getCurrentOffice() {
        //console.log('getCurrentOffice', this.currentOffice);
        return this.getOfficeByType(this.currentOffice);
    }

    getCurrentOfficeId() {
        //console.log('getCurrentOffice', this.currentOffice);
        return this.currentOfficeData.id;
    }

    saveCurrentOfficeToLocalStorage(type) {
        localStorage.setItem('currentOffice', type);
    }

    loadCurrentOfficeFromLocalStorage() {
        return localStorage.getItem('currentOffice');
    }

}

const officeManager = new OfficeManager(officesData);
export default officeManager;
