import React, { useEffect, useRef, useState } from 'react';
import { Box, Slide } from '@mui/material';
import { useAppContext } from '../components/AppContext.js';
import HeaderStandard from './HeaderStandard';
import HeaderOffice from './HeaderOffice';
import HeaderInitial from './HeaderInitial';
import HeaderOneTitle from './HeaderOneTitle';
import { headerTypes } from '../config/AppModes.ts';
import { AppRoutes } from '../config/AppModes.ts';
import { SafeArea } from 'capacitor-plugin-safe-area';

const Header = ({ setDirection, useDelayedNavigation }) => {
    const setDelayedNavigation = useDelayedNavigation();
    const { headerVisibility, headerType, setHeaderHeight } = useAppContext();
    const headerRef = useRef(null);
    const [safeAreaInsets, setSafeAreaInsets] = useState({ top: 0, bottom: 0, left: 0, right: 0 });

    useEffect(() => {
        if (headerRef.current) {
            const height = headerVisibility ? headerRef.current.clientHeight : 0;
            setHeaderHeight(height);
        }
    }, [headerVisibility, headerType, setHeaderHeight]);

    useEffect(() => {
        console.log('isNativePlatform?', window.Capacitor.isNativePlatform());
        if (window.Capacitor.isNativePlatform()) {
            console.log('Applying safe area adjustments for Capacitor in Native Platform...');
            SafeArea.getSafeAreaInsets().then(({ insets }) => {
                console.log('insets top:', insets.top);
                setSafeAreaInsets(insets);
            });
        } else {
            // For the web, you can apply standard CSS or manual adjustments
            setSafeAreaInsets({ top: 0, bottom: 0, left: 0, right: 0 });
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (headerRef.current) {
                const height = headerVisibility ? headerRef.current.clientHeight : 0;
                setHeaderHeight(height);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [headerVisibility, setHeaderHeight]);

    const onAvatarClick = () => {
        setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_PROFILE.path);
    };

    const onServiceLogoClick = () => {
        console.log('Service logo clicked');
    };

    const onBackButtonClick = () => {
        setDelayedNavigation(() => setDirection('backward'), -1);
    };

    const handleOfficeSelect = () => {
        setDelayedNavigation(() => setDirection('backward'), AppRoutes.ROUTE_HOME.path);
    };

    const handleProfileSelect = () => {
        setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_SET_PROFILE_CONTEXT.path);
    };

    const onSearchChange = (event) => {
        // Handle search input change
    };

    const renderHeader = () => {
        switch (headerType) {
            case headerTypes.STANDARD:
                return <HeaderStandard headerRef={headerRef} onBackButtonClick={onBackButtonClick} onAvatarClick={handleProfileSelect} onServiceLogoClick={onServiceLogoClick} />;
            case headerTypes.OFFICE:
                return <HeaderOffice headerRef={headerRef} handleOfficeSelect={handleOfficeSelect} onSearchChange={onSearchChange} />;
            case headerTypes.INITIAL:
                return <HeaderInitial headerRef={headerRef} onAvatarClick={onAvatarClick} setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />;
            case headerTypes.ONETITLE:
                return <HeaderOneTitle headerRef={headerRef} onBackButtonClick={onBackButtonClick} onAvatarClick={onAvatarClick} />;
            default:
                return <HeaderStandard headerRef={headerRef} onBackButtonClick={onBackButtonClick} onAvatarClick={onAvatarClick} onServiceLogoClick={onServiceLogoClick} />;
        }
    };

    return (
        headerVisibility && (
            <Slide in={headerVisibility} direction="down" timeout={100} easing="ease-in-out" unmountOnExit>
                <Box sx={{
                    zIndex: 1000,
                    paddingTop: safeAreaInsets.top,
                }}>
                    {renderHeader()}
                </Box>
            </Slide>
        )
    );
};

export default Header;
