// src/components/DeleteDialog.js
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function PopupDeleteDialog({ open, setOpen, onDelete, Title, Description }) {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    py: 4, px: 4,
                    height: 'auto',
                    borderRadius: '11px',
                    overflow: 'hidden',
                },
            }}
        >
            <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">{Title}</DialogTitle>
            <DialogContent sx={{ textAlign: 'center' }} >
                <DialogContentText id="alert-dialog-description">
                    "{Description}"
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Button sx={{ my: 1, minWidth: '60%' }} variant="outlined" onClick={() => setOpen(false)} color="primary">
                    {t('deleteConfirmation.cancel')}
                </Button>
                <Button sx={{ my: 1, minWidth: '60%' }} variant="danger" onClick={onDelete} color="secondary" autoFocus>
                    {t('deleteConfirmation.delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
