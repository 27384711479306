import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Fade } from '@mui/material';
import { ArrowBackIos as ArrowBackIcon } from '@mui/icons-material';
import { useAppContext } from '../components/AppContext.js';
import { useTranslation } from 'react-i18next';

const HeaderOneTitle = ({ headerRef, onBackButtonClick, onAvatarClick, onServiceLogoClick }) => {
    const { backButtonVisibility, headerTitle } = useAppContext();
    const { t } = useTranslation();

    return (
        <AppBar ref={headerRef} position="relative" sx={{ maxWidth: 'md', left: 0, backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.7)', color: 'black', boxShadow: 'none' }}>
            <Toolbar sx={{ m: 0, px: 1, boxShadow: 'none', maxWidth: '100%' }}>
                <Fade in={backButtonVisibility} timeout={500}>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={onBackButtonClick} sx={{ ml: 1 }}>
                        <ArrowBackIcon />
                    </IconButton>
                </Fade>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', pr: "4rem" }}>
                    <Typography variant="h2" sx={{
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',         // Hide overflowed text
                        textOverflow: 'ellipsis',   // Show ellipsis when text overflows
                    }}>
                        {headerTitle}
                    </Typography>
                </Box>

            </Toolbar>
        </AppBar>
    );
};

export default HeaderOneTitle;
