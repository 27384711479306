import { useState, useEffect } from 'react';

const useViewportHeight = () => {
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [initialViewportHeight, setInitialViewportHeight] = useState(window.innerHeight);
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

    useEffect(() => {
        const updateViewportHeight = () => {
            const height = window.visualViewport ? window.visualViewport.height : window.innerHeight;
            setViewportHeight(height);
            setIsKeyboardVisible(height < initialViewportHeight * 0.8); // Adjust the threshold as needed
        };

        setInitialViewportHeight(window.visualViewport ? window.visualViewport.height : window.innerHeight);
        updateViewportHeight();

        if (window.visualViewport) {
            window.visualViewport.addEventListener('resize', updateViewportHeight);
            window.visualViewport.addEventListener('scroll', updateViewportHeight);
        } else {
            window.addEventListener('resize', updateViewportHeight);
        }

        return () => {
            if (window.visualViewport) {
                window.visualViewport.removeEventListener('resize', updateViewportHeight);
                window.visualViewport.removeEventListener('scroll', updateViewportHeight);
            } else {
                window.removeEventListener('resize', updateViewportHeight);
            }
        };
    }, [initialViewportHeight]);

    return { viewportHeight, isKeyboardVisible };
};

export default useViewportHeight;
