// src/components/ConsultantManager.js
import { officeTypes } from '../config/AppModes.ts';
import { ChatModes } from '../config/AppModes.ts';

import allergist_female from '../img/ha-avatar/allergist-female.webp';
// import allergist_male from '../img/ha-avatar/allergist-male.webp';
import anesthesiologist_female from '../img/ha-avatar/anesthesiologist-female.webp';
// import anesthesiologist_male from '../img/ha-avatar/anesthesiologist-male.webp';
// import cardiologist_female from '../img/ha-avatar/cardiologist-female.webp';
import cardiologist_male from '../img/ha-avatar/cardiologist-male.webp';
import coloproctologist_female from '../img/ha-avatar/coloproctologist-female.webp';
// import coloproctologist_male from '../img/ha-avatar/coloproctologist-male.webp';
import cosmetologist_female from '../img/ha-avatar/cosmetologist-female.webp';
// import cosmetologist_male from '../img/ha-avatar/cosmetologist-male.webp';
// import dentist_female from '../img/ha-avatar/dentist-female.webp';
import dentist_male from '../img/ha-avatar/dentist-male.webp';
import dermatologist_female from '../img/ha-avatar/dermatologist-female.webp';
// import dermatologist_male from '../img/ha-avatar/dermatologist-male.webp';
import dietitian_female from '../img/ha-avatar/dietitian-female.webp';
// import dietitian_male from '../img/ha-avatar/dietitian-male.webp';
import endocrinologist_female from '../img/ha-avatar/endocrinologist-female.webp';
// import endocrinologist_male from '../img/ha-avatar/endocrinologist-male.webp';
// import endoscopist_female from '../img/ha-avatar/endoscopist-female.webp';
import endoscopist_male from '../img/ha-avatar/endoscopist-male.webp';
import gastroenterologist_female from '../img/ha-avatar/gastroenterologist-female.webp';
// import gastroenterologist_male from '../img/ha-avatar/gastroenterologist-male.webp';
// import gynecologist_female from '../img/ha-avatar/gynecologist-female.webp';
import gynecologist_male from '../img/ha-avatar/gynecologist-male.webp';
import immunologist_female from '../img/ha-avatar/immunologist-female.webp';
// import immunologist_male from '../img/ha-avatar/immunologist-male.webp';
import infectologist_female from '../img/ha-avatar/infectologist-female.webp';
// import infectologist_male from '../img/ha-avatar/infectologist-male.webp';
// import nephrologist_female from '../img/ha-avatar/nephrologist-female.webp';
import nephrologist_male from '../img/ha-avatar/nephrologist-male.webp';
import neurologist_female from '../img/ha-avatar/neurologist-female.webp';
// import neurologist_male from '../img/ha-avatar/neurologist-male.webp';
import oncologist_female from '../img/ha-avatar/oncologist-female.webp';
// import oncologist_male from '../img/ha-avatar/oncologist-male.webp';
// import ophthalmologist_female from '../img/ha-avatar/ophthalmologist-female.webp';
import ophthalmologist_male from '../img/ha-avatar/ophthalmologist-male.webp';
// import orthopedist_female from '../img/ha-avatar/orthopedist-female.webp';
import orthopedist_male from '../img/ha-avatar/orthopedist-male.webp';
import otolaryngologist_female from '../img/ha-avatar/otolaryngologist-female.webp';
// import otolaryngologist_male from '../img/ha-avatar/otolaryngologist-male.webp';
// import pediatrician_female from '../img/ha-avatar/pediatrician-female.webp';
import pediatrician_male from '../img/ha-avatar/pediatrician-male.webp';
import phlebologist_female from '../img/ha-avatar/phlebologist-female.webp';
// import phlebologist_male from '../img/ha-avatar/phlebologist-male.webp';
// import physiotherapist_female from '../img/ha-avatar/physiotherapist-female.webp';
import physiotherapist_male from '../img/ha-avatar/physiotherapist-male.webp';
// import plastic_surgeon_female from '../img/ha-avatar/plastic-surgeon-female.webp';
import plastic_surgeon_male from '../img/ha-avatar/plastic-surgeon-male.webp';
import psychiatrist_female from '../img/ha-avatar/psychiatrist-female.webp';
// import psychiatrist_male from '../img/ha-avatar/psychiatrist-male.webp';
import psychotherapist_female from '../img/ha-avatar/psychotherapist-female.webp';
// import psychotherapist_male from '../img/ha-avatar/psychotherapist-male.webp';
// import pulmonologist_female from '../img/ha-avatar/pulmonologist-female.webp';
import pulmonologist_male from '../img/ha-avatar/pulmonologist-male.webp';
import receptionist_female_1 from '../img/ha-avatar/receptionist-female-1.webp';
import rheumatologist_female from '../img/ha-avatar/rheumatologist-female.webp';
// import rheumatologist_male from '../img/ha-avatar/rheumatologist-male.webp';
import sexologist_female from '../img/ha-avatar/sexologist-female.webp';
// import sexologist_male from '../img/ha-avatar/sexologist-male.webp';
// import somnologist_female from '../img/ha-avatar/somnologist-female.webp';
import somnologist_male from '../img/ha-avatar/somnologist-male.webp';
// import surgeon_female from '../img/ha-avatar/surgeon-female.webp';
import surgeon_male from '../img/ha-avatar/surgeon-male.webp';
import therapist_female from '../img/ha-avatar/therapist-female.webp';
// import therapist_male from '../img/ha-avatar/therapist-male.webp';
import traumatologist_female from '../img/ha-avatar/traumatologist-female.webp';
// import traumatologist_male from '../img/ha-avatar/traumatologist-male.webp';
// import unknown from '../img/ha-avatar/surgeon-male.webp';
// import urologist_female from '../img/ha-avatar/urologist-female.webp';
import urologist_male from '../img/ha-avatar/urologist-male.webp';
// import vascular_surgeon_female from '../img/ha-avatar/vascular-surgeon-female.webp';
import vascular_surgeon_male from '../img/ha-avatar/vascular-surgeon-male.webp';

import tarot_intuitive from '../img/tarot-avatar/intuitive.webp';
import tarot_astrological from '../img/tarot-avatar/astrological.webp';
import tarot_spiritual from '../img/tarot-avatar/spiritual.webp';
import tarot_therapeutic from '../img/tarot-avatar/therapeutic.webp';
import tarot_numerological from '../img/tarot-avatar/numerological.webp';
import tarot_celtic from '../img/tarot-avatar/celtic.webp';
import tarot_oracle from '../img/tarot-avatar/oracle.webp';
import tarot_psychic from '../img/tarot-avatar/psychic.webp';
import tarot_classic from '../img/tarot-avatar/classic.webp';
import tarot_lenormand from '../img/tarot-avatar/lenormand.webp';

import psychologist_male from '../img/psycho-avatar/regular-psychologist.webp';
import family_psychologist_male from '../img/psycho-avatar/family-psychologist.webp';

import astral_natal_chart from '../img/astrology-avatar/natal-chart.webp';
import astral_horary_chart from '../img/astrology-avatar/horary-chart.webp';

import horoscope_chinese from '../img/horoscope-avatar/horoscope-chinese.webp';
import horoscope_compatibility from '../img/horoscope-avatar/horoscope-compatibility.webp';
import horoscope_business from '../img/horoscope-avatar/horoscope-business.webp';
import horoscope_lunar from '../img/horoscope-avatar/horoscope-lunar.webp';
import horoscope_zodiac from '../img/horoscope-avatar/horoscope-zodiac.webp';

import talisman_baca from '../img/talisman-avatar/talisman-baca.webp';
import talisman_calpaca from '../img/talisman-avatar/talisman-calpaca.webp';
import talisman_mandala from '../img/talisman-avatar/talisman-mandala_dos_orixas.webp';
import talisman_rosa from '../img/talisman-avatar/talisman-rosa_de_los_vientos.webp';
import talisman_trinkets from '../img/talisman-avatar/talisman-trinkets.webp';
import talisman_munekas from '../img/talisman-avatar/talisman-munekas_kuita_penas.webp';

const consultants = [
    {
        office: officeTypes.HEALTH,
        disclaimer: 'This tool is not a substitute for professional medical advice, diagnosis, or treatment. If you are experiencing a life-threatening emergency that requires immediate attention please call 911 or the number for your local emergency service.',
        consultants: [
            { photo: receptionist_female_1, key: ChatModes.CHAT_DOCTOR_RECEPTIONIST, name: 'AI Receptionist', description: 'Helps to select a specialist' },
            { photo: allergist_female, key: ChatModes.CHAT_DOCTOR_ALLERGIST, name: 'AI Allergist', description: 'Allergy specialist' },
            { photo: anesthesiologist_female, key: ChatModes.CHAT_DOCTOR_ANESTHESIOLOGIST, name: 'AI Anesthesiologist', description: 'Anesthesia specialist' },
            { photo: cardiologist_male, key: ChatModes.CHAT_DOCTOR_CARDIOLOGIST, name: 'AI Cardiologist', description: 'Heart health specialist' },
            { photo: coloproctologist_female, key: ChatModes.CHAT_DOCTOR_COLOPROCTOLOGIST, name: 'AI Coloproctologist', description: 'Colon and rectal expert' },
            { photo: cosmetologist_female, key: ChatModes.CHAT_DOCTOR_COSMETOLOGIST, name: 'AI Cosmetologist', description: 'Beauty and skin care expert' },
            { photo: dentist_male, key: ChatModes.CHAT_DOCTOR_DENTIST, name: 'AI Dentist', description: 'Oral health specialist' },
            { photo: dermatologist_female, key: ChatModes.CHAT_DOCTOR_DERMATOLOGIST, name: 'AI Dermatologist', description: 'Healthy skin' },
            { photo: dietitian_female, key: ChatModes.CHAT_DOCTOR_DIETITIAN, name: 'AI Dietitian', description: 'Nutrition expert' },
            { photo: endocrinologist_female, key: ChatModes.CHAT_DOCTOR_ENDOCRINOLOGIST, name: 'AI Endocrinologist', description: 'Hormone and metabolism expert' },
            { photo: endoscopist_male, key: ChatModes.CHAT_DOCTOR_ENDOSCOPIST, name: 'AI Endoscopist', description: 'Endoscopy expert' },
            { photo: gastroenterologist_female, key: ChatModes.CHAT_DOCTOR_GASTROENTEROLOGIST, name: 'AI Gastroenterologist', description: 'Digestive system expert' },
            { photo: gynecologist_male, key: ChatModes.CHAT_DOCTOR_GYNECOLOGIST, name: 'AI Gynecologist', description: 'Women\'s health expert' },
            { photo: immunologist_female, key: ChatModes.CHAT_DOCTOR_IMMUNOLOGIST, name: 'AI Immunologist', description: 'Immune system expert' },
            { photo: infectologist_female, key: ChatModes.CHAT_DOCTOR_INFECTIOLOGIST, name: 'AI Infectiologist', description: 'Infectious disease expert' },
            { photo: nephrologist_male, key: ChatModes.CHAT_DOCTOR_NEPHROLOGIST, name: 'AI Nephrologist', description: 'Kidney expert' },
            { photo: neurologist_female, key: ChatModes.CHAT_DOCTOR_NEUROLOGIST, name: 'AI Neurologist', description: 'Experts in Brain' },
            { photo: oncologist_female, key: ChatModes.CHAT_DOCTOR_ONCOLOGIST, name: 'AI Oncologist', description: 'Cancer treatment specialist' },
            { photo: ophthalmologist_male, key: ChatModes.CHAT_DOCTOR_OPHTHALMOLOGIST, name: 'AI Ophthalmologist', description: 'Eye specialist' },
            { photo: orthopedist_male, key: ChatModes.CHAT_DOCTOR_ORTHOPEDIST, name: 'AI Orthopedist', description: 'Bone and joint expert' },
            { photo: otolaryngologist_female, key: ChatModes.CHAT_DOCTOR_OTOLARYNGOLOGIST, name: 'AI Otolaryngologist', description: 'Ear, nose, and throat specialist' },
            { photo: pediatrician_male, key: ChatModes.CHAT_DOCTOR_PEDIATRICIAN, name: 'AI Pediatrician', description: 'Child health specialist' },
            { photo: phlebologist_female, key: ChatModes.CHAT_DOCTOR_PHLEBOLOGIST, name: 'AI Phlebologist', description: 'Vein health expert' },
            { photo: physiotherapist_male, key: ChatModes.CHAT_DOCTOR_PHYSIOTHERAPIST, name: 'AI Physiotherapist', description: 'Physical rehabilitation specialist' },
            { photo: plastic_surgeon_male, key: ChatModes.CHAT_DOCTOR_PLASTIC_SURGEON, name: 'AI Plastic Surgeon', description: 'Cosmetic surgery specialist' },
            { photo: psychiatrist_female, key: ChatModes.CHAT_DOCTOR_PSYCHIATRIST, name: 'AI Psychiatrist', description: 'Mental health expert' },
            { photo: psychotherapist_female, key: ChatModes.CHAT_DOCTOR_PSYCHOTHERAPIST, name: 'AI Psychotherapist', description: 'Therapy and counseling expert' },
            { photo: pulmonologist_male, key: ChatModes.CHAT_DOCTOR_PULMONOLOGIST, name: 'AI Pulmonologist', description: 'Lung and respiratory expert' },
            { photo: rheumatologist_female, key: ChatModes.CHAT_DOCTOR_RHEUMATOLOGIST, name: 'AI Rheumatologist', description: 'Arthritis and joint expert' },
            { photo: sexologist_female, key: ChatModes.CHAT_DOCTOR_SEXOLOGIST, name: 'AI Sexologist', description: 'Sexual health expert' },
            { photo: somnologist_male, key: ChatModes.CHAT_DOCTOR_SOMNOLOGIST, name: 'AI Somnologist', description: 'Sleep disorder expert' },
            { photo: surgeon_male, key: ChatModes.CHAT_DOCTOR_SURGEON, name: 'AI General Surgeon', description: 'Surgical care specialist' },
            { photo: therapist_female, key: ChatModes.CHAT_DOCTOR_THERAPIST, name: 'AI Therapist', description: 'General health expert' },
            { photo: traumatologist_female, key: ChatModes.CHAT_DOCTOR_TRAUMATOLOGIST, name: 'AI Traumatologist', description: 'Injury and trauma specialist' },
            { photo: urologist_male, key: ChatModes.CHAT_DOCTOR_UROLOGIST, name: 'AI Urologist', description: 'Urinary system and male reproductive health expert' },
            { photo: vascular_surgeon_male, key: ChatModes.CHAT_DOCTOR_VASCULAR_SURGEON, name: 'AI Vascular Surgeon', description: 'Blood vessel expert' },
        ]
    },
    {
        office: officeTypes.TAROT,
        disclaimer: '',
        consultants: [
            { photo: tarot_classic, key: ChatModes.CHAT_TARO_TRADITIONAL, name: 'Traditional Tarot Reader', description: 'Focus on traditional meanings and symbols' },
            { photo: tarot_lenormand, key: ChatModes.CHAT_TARO_LENORMAND, name: 'Lenormand Tarot Reader', description: 'Use Lenormand cards, a different but related divination system, alongside or instead of tarot' },
            { photo: tarot_oracle, key: ChatModes.CHAT_TARO_ORACLE, name: 'Oracle Deck Reader', description: 'Use oracle decks, which offer more flexible interpretations and themes compared to traditional tarot.' },
            { photo: tarot_celtic, key: ChatModes.CHAT_TARO_CELTIC, name: 'Celtic or Pagan Tarot Reader', description: 'Align their readings with Celtic, Pagan, or Wiccan spiritual practices.' },
            { photo: tarot_numerological, key: ChatModes.CHAT_TARO_NUMERICAL, name: 'Numerological Tarot Reader', description: 'Focus on the numerology associated with tarot cards for interpretations' },
            { photo: tarot_astrological, key: ChatModes.CHAT_TARO_ASTRO, name: 'Astrological Tarot Reader', description: 'Combine astrology with tarot, using astrological knowledge to deepen insights' },
            { photo: tarot_therapeutic, key: ChatModes.CHAT_TARO_THERAPY, name: 'Therapeutic Tarot Reader', description: 'Use tarot as a tool for personal development, often with a background in counseling or psychology' },
            { photo: tarot_spiritual, key: ChatModes.CHAT_TARO_SPIRIT, name: 'Spiritual Tarot Reader', description: 'Integrate spiritual beliefs and practices, such as meditation or prayer, into the reading' },
            { photo: tarot_intuitive, key: ChatModes.CHAT_TARO_INTUITIVE, name: 'Intuitive Tarot Reader', description: 'Rely on intuition and personal interpretation rather than strict adherence to card meanings' },
            { photo: tarot_psychic, key: ChatModes.CHAT_TARO_PSYCHIC, name: 'Psychic Tarot Readers', description: 'Combine tarot reading with psychic abilities, offering insights beyond the cards' },
        ]
    },
    {
        office: officeTypes.PSYCHO,
        disclaimer: '',
        consultants: [
            { photo: psychologist_male, key: ChatModes.CHAT_PSYCHO_REGULAR, name: 'Regular Psychologist', description: 'Mental health expert' },
            { photo: family_psychologist_male, key: ChatModes.CHAT_PSYCHO_FAMILY, name: 'Family Psychologist', description: 'Family therapy and counseling expert' }
        ]
    },
    {
        office: officeTypes.TALISMAN,
        disclaimer: '',
        consultants: [
            { photo: talisman_calpaca, key: ChatModes.CHAT_TALISMAN_CALPACA, name: 'Calpaca Talisman', description: 'Calpaca horseshoe' },
            { photo: talisman_rosa, key: ChatModes.CHAT_TALISMAN_ROSA, name: 'Rosa de los Vientos', description: 'Rosa de los Vientos talisman' },
            { photo: talisman_mandala, key: ChatModes.CHAT_TALISMAN_MANDALA, name: 'Mandala dos Orixás', description: 'Mandala dos Orixás figurine' },
            { photo: talisman_baca, key: ChatModes.CHAT_TALISMAN_BACA, name: 'Bacá', description: 'Bacá figurine' },
            { photo: talisman_munekas, key: ChatModes.CHAT_TALISMAN_KUITA, name: 'Munekas Kuita Peñas', description: 'Munekas Kuita Peñas doll' },
            { photo: talisman_trinkets, key: ChatModes.CHAT_TALISMAN_TRINKETS, name: 'Trinket', description: 'Trinket talisman' },
        ]
    },
    {
        office: officeTypes.ASTRAL,
        disclaimer: '',
        consultants: [
            { photo: astral_natal_chart, key: ChatModes.CHAT_ASTRAL_NATAL, name: 'Natal chart', description: 'Experts in Astrology' },
            { photo: astral_horary_chart, key: ChatModes.CHAT_ASTRAL_HORO, name: 'Horary astrology', description: 'Experts in Astrology' }
        ]
    },
    {
        office: officeTypes.HOROSCOPE,
        disclaimer: '',
        consultants: [
            { photo: horoscope_zodiac, key: ChatModes.CHAT_HOROSCOPE_REGULAR, name: 'Regular zodiac horoscope', description: 'Experts in Astrology' },
            { photo: horoscope_chinese, key: ChatModes.CHAT_HOROSCOPE_EASTERN, name: 'Eastern (Chinese) horoscope', description: 'Experts in Astrology' },
            { photo: horoscope_business, key: ChatModes.CHAT_HOROSCOPE_BUSINESS, name: 'Business horoscope', description: 'Experts in Astrology' },
            { photo: horoscope_compatibility, key: ChatModes.CHAT_HOROSCOPE_COMPATIBILITY, name: 'Compatibility horoscope', description: 'Experts in Astrology' },
            { photo: horoscope_lunar, key: ChatModes.CHAT_HOROSCOPE_LUNAR, name: 'Lunar horoscope​', description: 'Experts in Astrology' },
        ]
    }
];

const localStorageKey = 'currentConsultant';

class ConsultantManager {

    constructor() {
        this.selectedConsultant = this.getConsultantByKey(localStorage.getItem(localStorageKey)) || consultants[0].consultants[0];
    }

    getConsultants(officeType) {
        //console.log('getConsultants', officeType);
        const office = consultants.find(c => c.office === officeType);
        return office ? office.consultants : consultants[0].consultants;
    }

    getConsultantByKey(key) {
        for (const office of consultants) {
            const consultant = office.consultants.find(c => c.key === key);
            if (consultant) {
                return consultant;
            }
        }
        return null; // or you could throw an error if preferred
    }

    setSelectedConsultant(consultant) {
        this.selectedConsultant = consultant;
        localStorage.setItem(localStorageKey, consultant.key);
    }


    getSelectedConsultant() {
        console.log('getSelectedConsultant', this.selectedConsultant);
        return this.selectedConsultant;
    }
}

export default ConsultantManager;
