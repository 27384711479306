import api from './API.js';

/**
 * Profiles cache. That is a module variables,
 * so instantiated only at first module include.
 * The promise resolver function is used to initialize
 * cache when the auth token first available. Immeditely
 * at an attempt to load profiles the resolver variable
 * is reset to avoid second, third an so on initialization
 * calls
 */

class Cache {

    invalidate() {
        this.promise = new Promise(
            resolve => this.resolver = resolve
        );
    }

    actualize(token) {
        if(this.resolver) {
            api.allProfiles(token)
                .then((r => x => {
                    this.profiles = x;
                    r();
                })(this.resolver));
            this.resolver = null;
        }
    }
}

const cache_ = new Cache();
cache_.invalidate();

export function useProfile(token) {
    token && cache_.actualize(token);
    return {
        allProfiles: () => cache_.promise
            .then(() => cache_.profiles)
        , saveProfile: (p) => {
            return api
                .saveProfile(p, token)
                .then(x => {
                    cache_.invalidate();
                    cache_.actualize(token);
                    return x;
                });
        }
        , newProfile: (p) => {
            return api
                .newProfile(p, token)
                .then(x => {
                    cache_.invalidate();
                    cache_.actualize(token);
                    return x;
                });
        }
        , deleteProfile: (id) => {
            return api
                .deleteProfile(id, token)
                .then(x => {
                    cache_.invalidate();
                    cache_.actualize(token);
                    return x;
                });
        }
        , get_name: (id) => cache_.promise
            .then(() => cache_.profiles.reduce(
                (r, y) => (y.id == id && !!y.name)
                    ? y.name
                    : r
                , ""
            ))
    }
}

export function getAllProfilesSummary(token) {
    return api.getAllProfilesSummary(token);
} 
