// /src/classes/DocumentGenerator.js
import api from '../classes/API.js';

class DocumentGenerator {
    constructor() { }

    async readResponseStream(reader, onProgress) {

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            let data = {}
            try {
                //console.log("Event value", value);
                data = JSON.parse(value);
                //console.log("Event", data);
            }
            catch (error) {
                console.error("Error parsing JSON:", error, value);
                //throw error;
            }

            if (data.type === 'progress') {
                onProgress(data.progress);
            } else if (data.type === 'done') {
                return data.documentId;
            } else if (data.type === 'error') {
                throw new Error(data.error);
            }

        }

    }

    async generateDocument(data, token, onProgress) {
        try {
            const response = await api.apiGenerateDocument(data, token);

            if (response.ok) {
                const reader = response.body
                    .pipeThrough(new TextDecoderStream("utf-8"))
                    .getReader();

                return await this.readResponseStream(reader, onProgress);
            } else {
                throw new Error("Failed to initiate document generation");
            }
        } catch (error) {
            console.error("Document generation error:", error);
            throw error;
        }
    }
}

export default DocumentGenerator;
