import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext';
import { useTranslation } from 'react-i18next';

export function BillingScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);

    const { t } = useTranslation();

    const currentBalance = 10;  // Replace this with the actual balance
    const subscriptionPlan = t('billing.subscription.exclusive.title');  // Replace this with the actual plan

    const handleTopUp = () => {
        // Add top-up functionality
    };

    const handleChangePlan = () => {
        // Add change plan functionality
    };

    return (
        <Box sx={{
            pt: 1, pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',
            height: '100svh', width: '100%',
            background: 'rgba(255, 255, 255, 0.7)',
        }}>
            <Box sx={{
                display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', mt: 0,
            }}>
                <Box
                    sx={{
                        width: 'auto',
                        borderRadius: '20px 20px 0 0',
                        textAlign: 'center',
                        padding: 4,
                        position: 'relative',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography variant="h3" color="black" pr={2} sx={{ textAlign: 'left', width: 'auto' }} gutterBottom>
                            {t('billing.currentBalance')}
                        </Typography>
                        <Typography variant="h3" sx={{ textAlign: 'left' }} gutterBottom>
                            {currentBalance} USD
                        </Typography>
                    </Box>
                    <Button
                        variant="outlined"
                        onClick={handleTopUp}
                        sx={{ mt: 2, mb: 2 }}
                    >
                        {t('billing.topUp')}
                    </Button>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography variant="h3" color="black" pr={2} sx={{ textAlign: 'left', width: 'auto' }} gutterBottom>
                            {t('billing.subscriptionPlan')}
                        </Typography>
                        <Typography variant="h3" sx={{ textAlign: 'left' }} gutterBottom>
                            {subscriptionPlan}
                        </Typography>
                    </Box>
                    <Typography variant="body2" pt={2} sx={{ textAlign: 'left' }} gutterBottom>
                        {t('billing.subscription.exclusive.description')}
                    </Typography>
                    <Button
                        variant="outlined"
                        onClick={handleChangePlan}
                        sx={{ mt: 2 }}
                    >
                        {t('billing.changePlan')}
                    </Button>
                </Box>
            </Box>
        </Box >
    );
}

export default BillingScreen;
