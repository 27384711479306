import React, { useEffect, useState } from 'react';
import {
    AppBar
    , Toolbar
    , IconButton
    , Typography
    , Box
    , Badge
} from '@mui/material';
import { Card, CardActionArea, CardContent } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAuth } from '../components/AuthContext.js';
import { useAppContext } from '../components/AppContext.js'; // Import useAppContext hook
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../config/AppModes.ts';
import api from '../classes/API.js'
import { ProfileIcon } from '../components/ProfileIcon.tsx';

const HeaderInitial = ({ headerRef, onAvatarClick, setDirection, useDelayedNavigation }) => {

    const { token, isAuthenticated } = useAuth();
    const [others, setOthers] = useState([]);

    const { currentProfileId, setCurrentProfileId } = useAppContext();

    useEffect(
        () => {
            isAuthenticated
                && currentProfileId
                && api.allProfiles(token)
                    .then(x => setOthers(
                        x.filter(y => (y.id !== currentProfileId))
                    ));
        }
        , [
            isAuthenticated
            , currentProfileId
        ]
    );

    const { t } = useTranslation();

    const { officeManager } = useAppContext();
    const office = officeManager.getCurrentOffice();
    const officeTitle = office ? t(office.title) : null;
    const officeImage = office ? office.image : null;

    const setDelayedNavigation = useDelayedNavigation();

    function handleOffeSelect() {
        setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_SELECT_OFFICE.path);
    }

    const handleClick = (id) => {
        // Handle card click event
        setDelayedNavigation(() => setDirection('forward'), `${AppRoutes.ROUTE_PROFILE2.path}?profileId=${id}`);
    };

    return (
        <AppBar ref={headerRef} position="relative" sx={{ maxWidth: 'md', left: 0, backgroundColor: 'transparent', color: 'black', boxShadow: 'none' }}>

            <Box sx={{ px: 0, pb: 2 }}>
                <Card
                    sx={{
                        position: 'relative',
                        overflow: 'hidden',
                        width: '100%',
                        borderRadius: '0px',
                    }}
                    elevation={0}
                >
                    <CardActionArea onClick={handleOffeSelect}>
                        <CardContent sx={{ p: 0 }}>
                            <Box
                                sx={{
                                    backgroundImage: `url(${officeImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    aspectRatio: { xs: '20/9', md: '20/7' },
                                    borderRadius: '0px',
                                    justifyContent: 'left',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {/* Gradient overlay */}
                                <Box
                                    sx={{
                                        position: 'absolute', // Position over the image
                                        top: 0,
                                        left: 0,
                                        width: '100%', // Cover entire area
                                        height: '100%', // Cover entire area
                                        background:
                                            'linear-gradient(0deg, rgba(27, 1, 112, 0.85) 10%, rgba(255, 255, 255, 0) 48%)', // Gradient from left to right
                                    }}
                                />
                                <Box sx={{ flexGrow: 1 }}></Box>
                                <Typography
                                    sx={{
                                        px: { xs: 3, sm: 5, md: 6 },
                                        pb: { xs: 4, sm: 5, md: 5 },
                                        width: '70%',
                                        textAlign: 'left',
                                        position: 'relative',
                                    }}
                                    variant="h1"
                                    color="white"
                                >
                                    {officeTitle}
                                </Typography>
                            </Box>
                            {/* SVG Wave Shape */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '50px', // Adjust the height as needed
                                    overflow: 'hidden',
                                    lineHeight: 0,
                                    zIndex: 1, // Make sure it is above the content
                                }}
                            >
                                <svg
                                    viewBox="0 0 500 90"
                                    preserveAspectRatio="none"
                                    style={{ display: 'block', width: '100%', height: '100%' }}
                                >
                                    <path
                                        d="M0.00,49.98 C150.00,150.00 350.00,0.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                                        style={{ fill: '#fdfbfe' }} // Adjust the color as needed
                                    ></path>
                                </svg>
                            </Box>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: '0',
                                    width: '100%',
                                    height: '90px', // Adjust the height as needed
                                    overflow: 'hidden',
                                    lineHeight: 0,
                                    zIndex: 1, // Make sure it is above the content
                                    opacity: 0.5
                                }}
                            >
                                <svg
                                    viewBox="0 0 500 90"
                                    preserveAspectRatio="none"
                                    style={{ display: 'block', width: '100%', height: '100%' }}
                                >
                                    <path
                                        d="M0.00,49.98 C150.00,150.00 350.00,0.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                                        // style={{ fill: '#fdfeff' }} // Adjust the color as needed
                                        style={{ fill: '#fdfbfe' }} // Adjust the color as needed
                                    ></path>
                                </svg>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>

            <Toolbar sx={{ ml: 1, px: 1, boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}>

                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', ml: 0, mr: 0, my: 0.5 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ProfileIcon
                            id={currentProfileId}
                            onClick={() => handleClick(currentProfileId)}
                            sx={{
                                width: 71
                                , height: 71
                                , mr: 1.5
                                , ml: 0
                                , my: 1
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'left', justifyContent: 'left', width: '100%' }}>
                        <Typography variant="h3" sx={{ flexGrow: 1, textAlign: 'left', px: 0, whiteSpace: 'nowrap', ml: 0.5 }}>
                            {t('header.youAndYourBeloved')}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {others.map((x, ndx) => (
                                <ProfileIcon
                                    key={x.id}
                                    id={x.id}
                                    onClick={() => setCurrentProfileId(x.id)}
                                    sx={{
                                        width: 35
                                        , height: 35
                                        , ml: ndx === 0 ? 0 : 1
                                    }}
                                />
                            ))}
                            <IconButton
                                sx={{ m: 0, p: 0, color: '#3700AB' }}
                                onClick={() => handleClick(0)}
                            >
                                <AddCircleIcon sx={{ width: 35, height: 35, p: 0, m: 0.5 }}
                                />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', m: 0, p: 0, }}>
                        <IconButton sx={{ width: 71, height: 71, m: 0, p: 0 }}>
                            <Badge
                                color="error"
                                overlap="circular"
                                badgeContent="7"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <NotificationsNoneIcon />
                            </Badge>
                        </IconButton>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar >
    );
};

export default HeaderInitial;
