import React from 'react';
import { Box } from '@mui/material';

function CompletionCircle({ percentage }) {

    const progress = "〇◔◑◕◉";
    const index = Math.floor(percentage / 25);
    const progressIndicator = progress.slice(index, index + 1);

    //console.log(percentage, progressIndicator);

    return (
        <Box component="span" sx={{ display: 'inline-flex', fontSize: '0.8rem' }}>
            {progressIndicator}
        </Box>

    );
}

export default CompletionCircle;
