import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ChatMessage from '../components/ChatMessage.js';
import { useAppContext } from "../components/AppContext.js";

const MessageList = ({ messages, handleDeleteLastUserMessage, consultant }) => {

    const [consultantsArray, setConsultantsArray] = useState([]);
    const { consultantManager } = useAppContext();

    useEffect(() => {
        if (!messages || !consultant || !consultantManager) return;
        const updatedConsultantsArray = getConsultantsArray(messages);
        setConsultantsArray(updatedConsultantsArray);
    }, [messages, consultant, consultantManager]);

    function findLastUserMessageIndex(messages) {
        for (let i = messages.length - 1; i >= 0; i--) {
            if (messages[i].role === 'user') {
                return i
            }
        }
        return -1;
    }

    function getConsultantsArray(messages) {

        const consArray = [];
        let currCons = consultant;

        //console.log(messages);

        messages.forEach((message, index) => {
            if (message.role === 'joined') {
                currCons = consultantManager.getConsultantByKey(message.data.speciality);
                //console.log(currCons);
            }
            consArray[index] = currCons;
        });

        //console.log(consArray);

        return consArray;
    }


    return (
        <Grid container spacing={0} sx={{ width: '100%' }}>
            {messages.map((message, index) => (
                <ChatMessage
                    key={`${"chat-message"}-${index}`}
                    index={index}
                    message={message}
                    onLastMessageDelete={handleDeleteLastUserMessage}
                    lastUserMessageIndex={findLastUserMessageIndex(messages)}
                    length={messages.length}
                    consultant={consultant}
                    consultantsArray={consultantsArray} />
            ))}
        </Grid>
    );
}

export default MessageList;
