import React, {
    useState
    , useEffect
    , FC
} from 'react';
import { get_photo_url, on_update } from '../classes/PhotoStore.js';
import {
    IconButton
    , Fade
    , Avatar
    , SxProps
    , Theme
} from '@mui/material';
import { useAuth } from './AuthContext.js';
import { useProfile } from '../classes/ProfileManager.js';

const make_initials = name => name
    .split(" ")
    .reduce((r, x) => r += x[0] || "", "");

type TProfileIcon = FC<{
    id: bigint
    , name?: string
    , onClick: (id: bigint) => void
    , onImage?: (hasImage: boolean) => void
    , sx?: SxProps<Theme>
}>;
export const ProfileIcon: TProfileIcon = ({
    id
    , name
    , onClick
    , onImage
    , sx
}) => {
    const { token } = useAuth();
    const { get_name } = useProfile(token);
    const [image, setImage] = useState<string | undefined>();
    const [title, setTitle] = useState<string | undefined>();
    useEffect(
        () => {
            if (name) setTitle(make_initials(name))
        }
        , [
            name
        ]
    );
    useEffect(
        () => {
            onImage && onImage(!!image);
        }
        , [
            image
        ]
    );
    const set_image_url = y => token
        && get_photo_url(y, token)
            .then(x => setImage(x))
            .catch(x => setImage(undefined));
    useEffect(
        () => {
            if (id || id === 0n) {
                new Promise(
                    (resolve, reject) => name
                        ? resolve(name)
                        : get_name(id)
                            .then(x => resolve(x))
                )
                    .then(x => make_initials(x))
                    .then(t => setTitle(t))
                    .catch(() => setTitle("#"));
                set_image_url(id);
                return on_update(y => y == id
                    && set_image_url(y)
                );
            }
        }
        , [
            id
        ]
    )
    const [startFade, setStartFade] = useState(false);
    // Delay the activation of the fade effect
    useEffect(() => {
        const timer = setTimeout(() => {
            setStartFade(true);
        }, 1000); // Delay in milliseconds (e.g., 1000ms = 1 second)

        return () => clearTimeout(timer); // Cleanup the timeout on unmount
    }, []);

    return (
        <IconButton
            {...(sx && { sx: { ...sx } })}
            {...(onClick && { onClick: onClick })}
        >
            <Fade in={startFade} timeout={1000}>
                <Avatar
                    {...(sx && {
                        sx: {
                            width: sx.width
                            , height: sx.height
                        }
                    })}
                    src={image}
                >{title}</Avatar>
            </Fade>
        </IconButton>
    );
};