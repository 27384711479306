// AppModes.js

export const AppModes = {
    LANDING_ALL_SERVICES: 'landing-all-services',
    LANDING_HEALTHADVISOR: 'landing-healthadvisor',
    MODE_DASHBOARD: 'Dashboard',
    MODE_GENERATE_FORM: 'GenerateForm',
    CHAT_TARO: 'chat-tarot',
    CHAT_HOROSCOPE: 'chat-horoscope',
    CHAT_DOCTOR: 'chat-doctor',
    CHAT_DOCTOR_RECEPTIONIST: 'chat-doctor-receptionist',
    CHAT_DOCTOR_COPILOT: 'chat-doctor-copilot',
    CHAT_DOCTOR_COPILOT_DIAGNOSE: 'chat-doctor-copilot-diagnose',
    CHAT_DOCTOR_COPILOT_DIALOG: 'chat-doctor-copilot-dialog',
    CHAT_DOCTOR_PRESCREEN: 'chat-doctor-prescreen',
    MODE_NONE: 'none',
};

export const officeTypes = {
    HEALTH: 'health-advisor',
    TAROT: 'tarot',
    PSYCHO: 'psychological-help',
    TALISMAN: 'talisman',
    ASTRAL: 'astrology',
    HOROSCOPE: 'horoscope',
};


export const ChatModes = {


    CHAT_TARO: 'chat-tarot',
    CHAT_TARO_TRADITIONAL: 'chat-tarot-traditional',
    CHAT_TARO_LENORMAND: 'chat-tarot-lenormand',
    CHAT_TARO_ORACLE: 'chat-tarot-oracle',
    CHAT_TARO_CELTIC: 'chat-tarot-celtic',
    CHAT_TARO_NUMERICAL: 'chat-tarot-numerical',
    CHAT_TARO_ASTRO: 'chat-tarot-astro',
    CHAT_TARO_THERAPY: 'chat-tarot-therapy',
    CHAT_TARO_SPIRIT: 'chat-tarot-spirit',
    CHAT_TARO_INTUITIVE: 'chat-tarot-intuitive',
    CHAT_TARO_PSYCHIC: 'chat-tarot-psychic',

    CHAT_PSYCHO_REGULAR: 'chat-psycho-regular',
    CHAT_PSYCHO_FAMILY: 'chat-psycho-family',

    CHAT_HOROSCOPE: 'chat-horoscope',

    CHAT_DOCTOR: 'chat-doctor',
    CHAT_DOCTOR_RECEPTIONIST: 'chat-doctor-receptionist',
    CHAT_DOCTOR_CARDIOLOGIST: 'chat-doctor-cardiologist',
    CHAT_DOCTOR_DERMATOLOGIST: 'chat-doctor-dermatologist',
    CHAT_DOCTOR_ENDOCRINOLOGIST: 'chat-doctor-endocrinologist',
    CHAT_DOCTOR_GASTROENTEROLOGIST: 'chat-doctor-gastroenterologist',
    CHAT_DOCTOR_SURGEON: 'chat-doctor-surgeon',
    CHAT_DOCTOR_GYNECOLOGIST: 'chat-doctor-gynecologist',
    CHAT_DOCTOR_NEUROLOGIST: 'chat-doctor-neurologist',
    CHAT_DOCTOR_ONCOLOGIST: 'chat-doctor-oncologist',
    CHAT_DOCTOR_ORTHOPEDIST: 'chat-doctor-orthopedist',
    CHAT_DOCTOR_PEDIATRICIAN: 'chat-doctor-pediatrician',
    CHAT_DOCTOR_ALLERGIST: 'chat-doctor-allergist',
    CHAT_DOCTOR_IMMUNOLOGIST: 'chat-doctor-immunologist',
    CHAT_DOCTOR_ANESTHESIOLOGIST: 'chat-doctor-anesthesiologist',
    CHAT_DOCTOR_DIETITIAN: 'chat-doctor-dietitian',
    CHAT_DOCTOR_INFECTIOLOGIST: 'chat-doctor-infectiologist',
    CHAT_DOCTOR_COLOPROCTOLOGIST: 'chat-doctor-coloproctologist',
    CHAT_DOCTOR_COSMETOLOGIST: 'chat-doctor-cosmetologist',
    CHAT_DOCTOR_NEPHROLOGIST: 'chat-doctor-nephrologist',
    CHAT_DOCTOR_OTOLARYNGOLOGIST: 'chat-doctor-otolaryngologist',
    CHAT_DOCTOR_OPHTHALMOLOGIST: 'chat-doctor-ophthalmologist',
    CHAT_DOCTOR_PULMONOLOGIST: 'chat-doctor-pulmonologist',
    CHAT_DOCTOR_PSYCHIATRIST: 'chat-doctor-psychiatrist',
    CHAT_DOCTOR_PSYCHOTHERAPIST: 'chat-doctor-psychotherapist',
    CHAT_DOCTOR_PLASTIC_SURGEON: 'chat-doctor-plastic-surgeon',
    CHAT_DOCTOR_RHEUMATOLOGIST: 'chat-doctor-rheumatologist',
    CHAT_DOCTOR_SEXOLOGIST: 'chat-doctor-sexologist',
    CHAT_DOCTOR_VASCULAR_SURGEON: 'chat-doctor-vascular-surgeon',
    CHAT_DOCTOR_SOMNOLOGIST: 'chat-doctor-somnologist',
    CHAT_DOCTOR_DENTIST: 'chat-doctor-dentist',
    CHAT_DOCTOR_THERAPIST: 'chat-doctor-therapist',
    CHAT_DOCTOR_TRAUMATOLOGIST: 'chat-doctor-traumatologist',
    CHAT_DOCTOR_UROLOGIST: 'chat-doctor-urologist',
    CHAT_DOCTOR_PHYSIOTHERAPIST: 'chat-doctor-physiotherapist',
    CHAT_DOCTOR_PHLEBOLOGIST: 'chat-doctor-phlebologist',
    CHAT_DOCTOR_ENDOSCOPIST: 'chat-doctor-endoscopist',

    CHAT_TALISMAN_CALPACA: 'chat-talisman-calpaca',
    CHAT_TALISMAN_ROSA: 'chat-talisman-rosa',
    CHAT_TALISMAN_MANDALA: 'chat-talisman-mandala',
    CHAT_TALISMAN_BACA: 'chat-talisman-baca',
    CHAT_TALISMAN_KUITA: 'chat-talisman-kuita',
    CHAT_TALISMAN_TRINKETS: 'chat-talisman-trinkets',

    CHAT_ASTRAL_NATAL: 'chat-astral-natal',
    CHAT_ASTRAL_HORO: 'chat-astral-horo',

    CHAT_HOROSCOPE_REGULAR: 'chat-horoscope-regular',
    CHAT_HOROSCOPE_EASTERN: 'chat-horoscope-eastern',
    CHAT_HOROSCOPE_BUSINESS: 'chat-horoscope-business',
    CHAT_HOROSCOPE_COMPATIBILITY: 'chat-horoscope-compatibility',
    CHAT_HOROSCOPE_LUNAR: 'chat-horoscope-lunar',

};


export const AppActions = {
    ACTION_LOGIN: 'login',
    ACTION_SIGNUP: 'signup',
    ACTION_LOGOUT: 'logout',
    ACTION_NONE: 'none',
};

export const AppService = {
    SERVICE_NONE: { KEY: 'none', TITLE: 'None' },
    SERVICE_DOCTOR: { KEY: 'doctor', TITLE: 'Health Advisor' },
    SERVICE_HEALTH: { KEY: 'health', TITLE: 'Health Advisor' },
    SERVICE_BLOG: { KEY: 'blog', TITLE: 'Blog Post Writer' },
    SERVICE_WRITER: { KEY: 'writer', TITLE: 'AI Universal Writer' },
    SERVICE_ARTICLE: { KEY: 'article', TITLE: 'Article Writer' },
    SERVICE_ANY: { KEY: 'any', TITLE: 'Wixee' }
}

export const headerTypes = {
    STANDARD: 'standard',
    OFFICE: 'office',
    INITIAL: 'initial',
    ONETITLE: 'onetitle',
};

export const footerTypes = {
    STANDARD: 'standard',
    CONSULTATION: 'consultation',
};


export const AppFooterElements = {
    FOOTER_ELEMENT_HOME: 'home',
    FOOTER_ELEMENT_CONSULTATIONS: 'consultation',
    FOOTER_ELEMENT_DOCUMENTS: 'documents',
    FOOTER_ELEMENT_NOTIFICATIONS: 'notifications',
    FOOTER_ELEMENT_PROFILE: 'profile',
}

// Screen configurations based on the location path
export const AppRoutes = {

    ROUTE_WELCOME: { path: '/', header: false, footer: false },
    ROUTE_SPLASH1: { path: '/splash1', header: false, footer: false },
    ROUTE_SPLASH2: { path: '/splash2', header: false, footer: false },
    ROUTE_SPLASH3: { path: '/splash3', header: false, footer: false },
    ROUTE_SPLASH4: { path: '/splash4', header: false, footer: false },

    ROUTE_LOGIN: { path: '/login', header: false, footer: false },
    ROUTE_LOGIN_BY_EMAIL: { path: '/loginbyemail', header: false, footer: false },
    ROUTE_GOOGLELOGIN: { path: '/googlelogin', header: false, footer: false },

    ROUTE_HOME: { path: '/home', header: false, footer: true, footerItem: AppFooterElements.FOOTER_ELEMENT_HOME, footerType: footerTypes.STANDARD },
    ROUTE_SELECT_OFFICE: { path: '/select/office', header: false, footerItem: AppFooterElements.FOOTER_ELEMENT_HOME, footer: true, footerType: footerTypes.STANDARD },

    ROUTE_PROFILE: { path: '/profile', header: false, footer: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, footerType: footerTypes.STANDARD },
    ROUTE_SELECT_PROFILE: { path: '/select/profile', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'profile.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },
    ROUTE_SET_PROFILE_CONTEXT: { path: '/set/profile', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'profile.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },
    ROUTE_PROFILE2: { path: '/profile2', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'profile.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },

    ROUTE_OFFICES: { path: '/offices', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'offices.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },
    ROUTE_SETTINGS: { path: '/settings', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'settings.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },
    ROUTE_BILLING: { path: '/billing', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'billing.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },

    ROUTE_DOCUMENTS: { path: '/documents', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_DOCUMENTS, headerTitle: 'documents.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_CALENDAR: { path: '/calendar', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_NOTIFICATIONS, headerTitle: 'calendar.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_NOTIFICATIONS: { path: '/notifications', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_NOTIFICATIONS, headerTitle: 'notifications.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_CONSULTATIONS: { path: '/consultations', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS, headerTitle: 'consultations.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_SELECT_CONSULTANT: { path: '/consultant', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS, headerTitle: 'consultations.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_CONSULTATION_ID: { path: '/consultation', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS, headerTitle: 'consultation.title', headerType: headerTypes.STANDARD, footerType: footerTypes.CONSULTATION },
    ROUTE_CONSULTATION_DETAILS: { path: '/consultation/details', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS, headerTitle: 'consultationDetails.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_DOCUMENT_DETAILS: { path: '/document/details', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_DOCUMENTS, headerTitle: 'documentDetails.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_DOCUMENT_MANAGE: { path: '/document/manage', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_DOCUMENTS, headerTitle: 'documentManage.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
}


