import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const AlertBox = ({ open, message, severity = "success" }) => {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');


    const handleSnackbarOpen = (message, severity = "success") => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (open) {
            handleSnackbarOpen(message, severity);
        }
    }, [open]);

    return (
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            sx={{
                opacity: 1,
                top: { xs: 20, sm: 20, }, // Adjust the top position as needed
                width: { xs: '90%', sm: '60%', md: '40%' }, // Adjust the width as needed
                maxWidth: '25em',
            }}
            key="appAlert"
        >
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity} icon={<InfoIcon />}
                sx={{
                    width: '100%',
                    backgroundColor: '#AAEEAA', // Blue background color
                    color: '#000000', // White text color
                    fontSize: '1.2rem', // Adjust the font size as needed
                    borderRadius: '11px',
                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
                    p: 2,
                }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    );
};