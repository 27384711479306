import React from 'react';
import { Avatar, Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';

const ConsultantCard = ({ consultant, handleCardClick }) => (
    <Card sx={{ overflow: 'hidden', p: 0.5, mb: 0, borderRadius: '11px' }} elevation={0}>
        <CardActionArea onClick={handleCardClick}>
            <CardContent sx={{ p: 0 }}>
                <Box sx={{
                    width: '98%', borderRadius: '11px',
                    justifyContent: 'center', position: 'relative', overflow: 'auto',
                    border: '1px solid #E5E7EB', p: 0.5,
                    background: '#CBE6FF'
                }}>
                    <Box sx={{
                        height: '100%', width: '100%', justifyContent: 'top', display: 'flex',
                        flexDirection: 'row', alignItems: 'center',
                    }}>
                        <Box sx={{ width: '60px', height: '60px', overflow: 'hidden', display: 'flex', alignItems: 'flex-start', borderRadius: '5px', p: 1, }}>
                            <Avatar sx={{ width: '60px', height: 'auto', transform: 'scale(1)', transformOrigin: 'top', borderRadius: '5px' }} src={consultant?.photo} />
                        </Box>
                        <Box sx={{ p: 0.3, width: '80%', textAlign: 'left', position: 'relative', }}>
                            <Typography sx={{
                                p: 0, width: '100%',
                                textAlign: 'left', position: 'relative',
                                wordWrap: 'break-word', // Old CSS3 property, use as a fallback
                                wordBreak: 'break-word', // Ensures that the words can be broken and wrapped at arbitrary points if needed
                                overflowWrap: 'break-word', // Standard property to break words to prevent overflow
                                hyphens: 'auto' // Automatically add hyphens when breaking words (works best in supported languages)

                            }} variant="h3">
                                {consultant?.name}
                            </Typography>
                            <Typography sx={{ p: 0, width: '80%', textAlign: 'left', position: 'relative', mt: 0 }} variant="body1">
                                {consultant?.description}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ px: 1, pb: 1, }}>
                        <Typography sx={{ width: '100%', textAlign: 'left', position: 'relative', pt: 1, }} variant="body2">
                            {consultant?.disclaimer}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>
);

export default ConsultantCard;
