import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box, Card, CardActionArea, CardContent, Grid } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext.js';  // Import useAuth from AuthContext
import { Avatar, } from '@mui/material';
import { useAppContext, } from '../components/AppContext.js'; // Import useAppContext hook
import { AppRoutes } from '../config/AppModes.ts';

export function SelectConsultant({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);
    const setDelayedNavigation = useDelayedNavigation();
    const [consultants, setConsultants] = useState([]);

    const { officeManager, consultantManager } = useAppContext();

    useEffect(() => {
        if (!consultantManager || !officeManager) return;

        setConsultants(consultantManager.getConsultants(officeManager.getCurrentOffice().type))
    }, [consultantManager, officeManager]);

    const handleCardClick = (consultant) => {
        // Handle card click event
        //console.log('Card clicked:', profile);
        consultantManager.setSelectedConsultant(consultant);
        setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_CONSULTATION_ID.path);
    };

    return (
        <Box sx={{
            pt: 3, pb: 10, display: 'flex', flexDirection: 'column',
            alignItems: 'center', width: '100%', height: 'auto',
            backgroundColor: 'rgba(255, 255, 255, 0.7)'
        }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

                <Grid container spacing={2} sx={{ px: { xs: 1, md: 2 }, width: '100%' }}>

                    {consultants.map((consultant, index) => (

                        <Grid key={index} item xs={6} sm={4} md={4}>
                            <Card key={index} sx={{
                                overflow: 'hidden', p: 0, mb: 0, borderRadius: '11px',
                                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                            }} elevation={0}>
                                <CardActionArea onClick={() => { handleCardClick(consultant) }}>
                                    <CardContent sx={{ p: 0 }}>
                                        <Box sx={{
                                            width: '100%', height: '100%', borderRadius: '11px',
                                            justifyContent: 'center',
                                            position: 'relative',
                                            border: '1px solid #DDDDDD',
                                            pb: 3,
                                        }}>
                                            {/* Gradient overlay */}
                                            <Box sx={{
                                                position: 'absolute', // Position over the image
                                                width: '100%', // Cover entire area
                                                height: '100%', // Cover entire area
                                                background: 'linear-gradient(to bottom, #CCDDDD, rgba(255,255,255,0))', // Gradient from left to right
                                            }} />
                                            <Box sx={{
                                                height: '100%', width: '100%', justifyContent: 'top', display: 'flex',
                                                flexDirection: 'column', alignItems: 'center', pt: 0,
                                            }}>
                                                <Box sx={{ width: '100%', aspectRatio: '1/2', maxHeight: '16rem', overflow: 'hidden', display: 'flex', alignItems: 'flex-start', mb: 1, pt: 0, }}>
                                                    <Avatar sx={{ width: '100%', height: 'auto', borderRadius: '10px' }} src={consultant.photo} />
                                                </Box>
                                                <Typography sx={{
                                                    p: 0,
                                                    width: '80%',
                                                    textAlign: 'center',
                                                    position: 'relative',
                                                    wordWrap: 'break-word', // Old CSS3 property, use as a fallback
                                                    wordBreak: 'break-word', // Ensures that the words can be broken and wrapped at arbitrary points if needed
                                                    overflowWrap: 'break-word', // Standard property to break words to prevent overflow
                                                    hyphens: 'auto' // Automatically add hyphens when breaking words (works best in supported languages)
                                                }} variant="h3">
                                                    {consultant.name}
                                                </Typography>
                                                <Typography sx={{ mt: 0.5, p: 0, width: '80%', textAlign: 'center', position: 'relative', }} variant="body2">
                                                    {consultant.description}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Box>
        </Box >



    );
}

export default SelectConsultant;