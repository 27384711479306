import React, { useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBack';
import logo from '../img/wixee-magistral.svg';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../config/AppModes.ts';

export function SplashScreen4({ setDirection, useDelayedNavigation }) {
    const setDelayedNavigation = useDelayedNavigation();
    const { t } = useTranslation();

    return (
        <Box sx={{
            background: 'linear-gradient(to bottom, #7838FF, #1F0061)',
            height: '100svh',
            display: 'flex',
            flex: 1,
            flexDirection: 'column', padding: 0,
            overflow: 'hidden'
        }}>
            <Box sx={{ background: 'white', height: '100%' }}>
                <Box
                    sx={{
                        pt: 10,
                        pb: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'black',
                        height: '100%',
                    }}
                >

                    <img src={logo} alt="Wixee Logo" style={{ width: '20rem' }} />
                    {/* <Typography sx={{ mx: 8, mt: 4, width: '14rem', textAlign: 'center' }} variant="h1">Wixee</Typography> */}
                    <Typography variant="h1" sx={{ mx: 8, mt: 10, textAlign: 'center' }}>{t('splash4.title')}</Typography>
                    <Typography className="size-default color-default" sx={{ px: 4, my: 2, textAlign: 'center' }} variant="body1">{t('splash4.description')}</Typography>
                    <Box sx={{ my: 2 }}>
                        <Button sx={{ width: '12rem' }} variant="contained" color="primary" onClick={() => setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_LOGIN.path)} >
                            {t('splash4.signInButton')}
                        </Button>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Button variant="outlined" color="primary"
                            onClick={() => setDelayedNavigation(() => setDirection('backward'), AppRoutes.ROUTE_SPLASH3.path)} sx={{ mr: 1 }}>
                            <ArrowBackIosIcon />
                        </Button>
                    </Box>
                </Box>

            </Box>
        </Box >
    );
}

export default SplashScreen4;