import React, { useState, useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Badge } from '@mui/material';
import { ReactComponent as HomeSolidIcon } from '../img/icons/navigation/icon-home-solid.svg';
import { ReactComponent as HomeOutlinedIcon } from '../img/icons/navigation/icon-home-outlined.svg';
import { ReactComponent as DocSolidIcon } from '../img/icons/navigation/icon-doc-solid.svg';
import { ReactComponent as DocOutlinedIcon } from '../img/icons/navigation/icon-doc-outlined.svg';
import { ReactComponent as CalendarSolidIcon } from '../img/icons/navigation/icon-calendar-solid.svg';
import { ReactComponent as CalendarOutlinedIcon } from '../img/icons/navigation/icon-calendar-outlined.svg';
import { ReactComponent as ProfileSolidIcon } from '../img/icons/navigation/icon-profile-solid.svg';
import { ReactComponent as ProfileOutlinedIcon } from '../img/icons/navigation/icon-profile-outlined.svg';
import { ReactComponent as ConsultationIcon } from '../img/icons/navigation/icon-consultation.svg';
import { ReactComponent as ConsultationIconSolid } from '../img/icons/navigation/icon-consultation-solid.svg';

import { useAppContext } from '../components/AppContext.js';
import { AppFooterElements } from '../config/AppModes.ts';

const footerOrder = [
    AppFooterElements.FOOTER_ELEMENT_HOME,
    AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS,
    AppFooterElements.FOOTER_ELEMENT_DOCUMENTS,
    AppFooterElements.FOOTER_ELEMENT_NOTIFICATIONS,
    AppFooterElements.FOOTER_ELEMENT_PROFILE,
];

const FooterStandard = ({ setDirection, useDelayedNavigation }) => {
    const [value, setValue] = useState(0);
    const setDelayedNavigation = useDelayedNavigation();
    const { screenRoute } = useAppContext();

    function detectDirection(targetElement) {
        const currentIndex = footerOrder.indexOf(screenRoute.footerItem);
        const targetIndex = footerOrder.indexOf(targetElement);
        return targetIndex > currentIndex ? 'forward' : 'backward';
    }

    const handleNavigation = (direction, path) => {
        setDelayedNavigation(() => setDirection(direction), path);
    };

    useEffect(() => {
        //console.log('Footer:', screenRoute.footerItem);
        setValue(screenRoute.footerItem);
    }, [screenRoute]);

    return (
        <BottomNavigation
            value={value}
            showLabels={false}
            sx={{
                position: 'relative', bottom: 0, width: '100%',
                zIndex: 1000, backgroundColor: 'white',
                py: 1,
            }}
        >
            <BottomNavigationAction
                label=""
                icon={
                    <Badge
                        color="error"
                        overlap="circular"
                        badgeContent=""
                        variant="dot"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {value === AppFooterElements.FOOTER_ELEMENT_HOME ? <HomeSolidIcon /> : <HomeOutlinedIcon />}
                    </Badge>
                }
                onClick={() => handleNavigation(detectDirection(AppFooterElements.FOOTER_ELEMENT_HOME), '/home')}
            />

            <BottomNavigationAction
                label=""
                icon={value === AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS ? <ConsultationIconSolid /> : <ConsultationIcon />}
                onClick={() => handleNavigation(detectDirection(AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS), '/consultations')}
            />

            <BottomNavigationAction
                label=""
                icon={value === AppFooterElements.FOOTER_ELEMENT_DOCUMENTS ? <DocSolidIcon /> : <DocOutlinedIcon />}
                onClick={() => handleNavigation(detectDirection(AppFooterElements.FOOTER_ELEMENT_DOCUMENTS), '/documents')}
            />

            <BottomNavigationAction
                label=""
                icon={
                    <Badge
                        color="error"
                        overlap="circular"
                        badgeContent=" "
                        variant="dot"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {value === AppFooterElements.FOOTER_ELEMENT_NOTIFICATIONS ? <CalendarSolidIcon /> : <CalendarOutlinedIcon />}
                    </Badge>
                }
                onClick={() => handleNavigation(detectDirection(AppFooterElements.FOOTER_ELEMENT_NOTIFICATIONS), '/calendar')}
            />

            <BottomNavigationAction
                label=""
                icon={value === AppFooterElements.FOOTER_ELEMENT_PROFILE ? <ProfileSolidIcon /> : <ProfileOutlinedIcon />}
                onClick={() => handleNavigation(detectDirection(AppFooterElements.FOOTER_ELEMENT_PROFILE), '/profile')}
            />
        </BottomNavigation>
    );
};

export default FooterStandard;
