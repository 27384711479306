
// ./classes/API.js
const API_ENDPOINT = (process.env.REACT_APP_PROD_CONNECT) ? process.env.REACT_APP_API_ENDPOINT_PROD : process.env.REACT_APP_API_ENDPOINT;

class API {

    constructor() {
        this._isMounted = false; // Initialize the flag
        this.token = localStorage.getItem('authToken');
    }

    async fetchWithFallback(method, options) {

        const apiEndpoint = API_ENDPOINT + method;
        if (this.token) (options.headers || (options.headers = {}))
            .Authorization = "Bearer " + this.token;
        return fetch(apiEndpoint, options)
            .then(
                x => {
                    console.log(`Request to ${apiEndpoint} succeeded with status ${x.status}`);
                    return x;
                }
            );
    }

    async apiLogin(data) {

        return await this.fetchWithFallback("/login", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    }

    async apiUpdateUserProfile(data) {

        return await this.fetchWithFallback("/updateuserprofile", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    }

    async verifyToken(data) {

        return await this.fetchWithFallback("/verifytoken", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    }

    async apiGoogleLogin(data) {

        return await this.fetchWithFallback("/googlelogin", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    }

    async apiGetInfo(data, token) {

        return await this.fetchWithFallback("/getInfo", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include',

        });
    }

    async apiGetData(data, token) {
        //console.log('apiGetData:', token);

        return await this.fetchWithFallback("/getData", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include',
        });
    }


    async getConsultations(profileId, token) {
        return await this.fetchWithFallback(`/consultations/profile/${profileId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include',
        });
    }

    async getConsultationsByUser(userId, officeId, token) {
        return await this.fetchWithFallback(`/consultations/user/${userId}?officeId=${officeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include',
        });
    }

    async getConsultationById(consId, token) {
        return await this.fetchWithFallback(`/consultations/${consId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include',
        });
    }

    async deleteConsultation(consId, token) {
        const response = await this.fetchWithFallback(`/consultations/${consId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response;
    }

    async restoreConsultation(consId, token) {
        const response = await this.fetchWithFallback(`/consultations/restore/${consId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response;
    }

    async apiGetChatResponse(body, token) {

        return await this.fetchWithFallback("/chat", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body),
            credentials: 'include',
        });
    }


    async apiSaveChatMessages(body, token) {

        return await this.fetchWithFallback("/savechat", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body),
            credentials: 'include',
        });
    }


    async apiRequestInstantLogin(email) {
        return await this.fetchWithFallback("/authCodeReq", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({ id: email }),
            credentials: 'include',
        });
    }

    async apiInstantLogin(email, code) {
        return await this.fetchWithFallback("/authCode", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({ id: email, code: code }),
            credentials: 'include',
        });
    }

    async allProfiles(token) {
        const response = await this.fetchWithFallback("/profile", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include',
        });
        const res = await response.json();
        res.forEach(x => x.date_of_birth = new Date(x.date_of_birth))
        return res;
    }

    async getProfile(profileId, token) {
        const response = await this.fetchWithFallback(`/profile/${profileId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include',
        });
        let x = await response.json();
        x.date_of_birth = new Date(x.date_of_birth);
        return x;
    }

    async newProfile(profile, token) {
        const r = await this.fetchWithFallback("/profile/new", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...profile
                , date_of_birth: profile.date_of_birth.toISOString()
            }),
            credentials: 'include',
        });
        return r.json();
    }

    async saveProfile(profile, token) {
        const r = await this.fetchWithFallback(`/profile/${profile.id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...profile
                , date_of_birth: profile.date_of_birth.toISOString()
            }),
            credentials: 'include',
        });
        return r.json();
    }

    async deleteProfile(profile_id, token) {
        return this.fetchWithFallback(`/profile/${profile_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include',
        });
    }

    async storePhoto(profile_id, file_data, token) {
        const form_data = new FormData();
        form_data.append('avatar', file_data);
        return await this.fetchWithFallback(`/profile/photo/${profile_id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: form_data,
            credentials: 'include',
        });
    }

    async loadPhoto(profileId, hash, token) {
        return await this.fetchWithFallback(`/profile/photo/${profileId}`, {
            method: 'GET',
            headers: (h => {
                if (hash) h['If-None-Match'] = hash;
                return h;
            })({
                'Authorization': `Bearer ${token}`
                ,
            }),
            credentials: 'include',
        });
    }


    async deletePhoto(profileId, token) {
        return await this.fetchWithFallback(`/profile/photo/${profileId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include',
        });
    }

    async stats(token) {
        const r = await this.fetchWithFallback("/stats", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include',
        });
        return r.json();
    }

    async apiGetWebSocketTranscribe(body) {
        try {
            console.log("Trying socket connection to URL: " + API_ENDPOINT);
            const ws = await this.connectWebSocket(API_ENDPOINT);
            return ws; // Return the successfully connected WebSocket instance
        } catch (error) {
            console.log(error);
            // Attempt to connect to the alternative URL if the initial connection fails
            /*
            try {
                console.log("Trying alternative URL due to error: " + API_ENDPOINT_ALTERNATIVE);
                const wsAlternative = await this.connectWebSocket(API_ENDPOINT_ALTERNATIVE);
                return wsAlternative; // Return the WebSocket instance connected to the alternative URL
            } catch (error) {
                console.log("Error with alternative URL", error);
                return null; // Return null if both connection attempts fail
            }
            */
        }
    }

    async connectWebSocket(url) {
        return new Promise((resolve, reject) => {
            const ws = new WebSocket(url);

            ws.addEventListener('open', () => {
                //console.log("Connected to WebSocket at " + url);
                resolve(ws); // Resolve the promise with the WebSocket instance upon successful connection
            });

            ws.addEventListener('error', (event) => {
                console.log("Error connecting to WebSocket at " + url, event);
                reject(new Error("Failed to connect to WebSocket at " + url)); // Reject the promise on connection error
            });
        });
    }

    async apiGenerateDocument(data, token) {
        const response = await this.fetchWithFallback("/generateDocument", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${this.token}`,
            },
            body: JSON.stringify(data),
            credentials: 'include',
        });

        return response;
    }

    async generateImage(data) {
        const response = await this.fetchWithFallback("/generateImage", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${this.token}`,
            },
            body: JSON.stringify(data),
            credentials: 'include',
        });

        return response;
    }


    async apiGetDocumentContent(documentHash) {
        return await this.fetchWithFallback(`/documents/content/${documentHash}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
            },
            credentials: 'include',
        });
    }

    async apiGetDocument(documentId) {
        return await this.fetchWithFallback(`/documents/${documentId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
            },
            credentials: 'include',
        });
    }

    async apiGetDocuments(profileId, officeId) {
        return await this.fetchWithFallback(`/documents/profile/${profileId}?officeId=${officeId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
            },
            credentials: 'include',
        });
    }

    async deleteDocument(docId, token) {
        const response = await this.fetchWithFallback(`/documents/${docId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response;
    }

    async restoreDocument(docId, token) {
        const response = await this.fetchWithFallback(`/documents/restore/${docId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response;
    }

    async uploadDocument(formData, token) {

        return await this.fetchWithFallback(`/documents/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
            credentials: 'include',
        });
    }

    async uploadPhoto(formData, token) {

        return await this.fetchWithFallback(`/documents/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
            credentials: 'include',
        });
    }

    async getAllProfilesSummary(token) {
        return await this.fetchWithFallback(`/profiles/allsummary`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include',
        });
    }

}

const api = new API();

export default api;
