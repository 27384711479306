import { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../components/AppContext.js';
import TranscriptionService from '../classes/TranscriptionService.js';
import { useTranslation } from 'react-i18next';

const useSpeechButtonLogic = ({ onSpeechFinished, language, setIsLoading }) => {
    const { t } = useTranslation();
    const { showAlert } = useAppContext();

    const [isRecording, setIsRecording] = useState(false);
    const [transcriptionChunks, setTranscriptionChunks] = useState([]);
    const transcriptionService = useRef(null);

    useEffect(() => {
        transcriptionService.current =
            new TranscriptionService(language, setTranscriptionChunks, setIsRecording, true);
        return () => {
            // Cleanup on component unmount
            transcriptionService.current.stopRecording();
        };
    }, []);

    const startRecording = async () => {
        if (!isRecording) {
            setTranscriptionChunks([]);
        }
        console.log("Start recording.");
        transcriptionService.current.startRecording();
    };

    const stopRecording = async () => {
        if (isRecording) {
            console.log("Stop recording.");
            transcriptionService.current.stopRecording();
            onSpeechFinished(transcriptionChunks.map(chunk => chunk.transcript).join(' '));
            setTranscriptionChunks([]);
            setIsRecording(false);
        }
    };

    useEffect(() => {
        setIsLoading(isRecording);
        if (!isRecording && transcriptionChunks.length > 0) {
            onSpeechFinished(transcriptionChunks.map(chunk => chunk.transcript).join(' '));
        }
    }, [isRecording]);

    return {
        isRecording,
        startRecording,
        stopRecording
    };
};

export default useSpeechButtonLogic;
