// PageIndicator.tsx
import React from 'react';
import { Box } from '@mui/material';

interface PageIndicatorProps {
    page: number;
}

const PageIndicator: React.FC<PageIndicatorProps> = ({ page }) => {
    const indicators = [1, 2, 3];

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            {indicators.map(index => (
                <Box
                    key={index}
                    sx={{
                        width: 12,
                        height: 4,
                        backgroundColor: index === page ? 'rgba(60, 0, 139, 1)' : 'rgba(60, 0, 139, 0.3)',
                        borderRadius: '30%',
                        mx: 0.4,
                        mt: 1.2,
                    }}
                />
            ))}
        </Box>
    );
};

export default PageIndicator;
