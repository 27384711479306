import { useEffect, useState, useRef, useCallback } from 'react';
import { extractJson } from "../utils/utils.js";
import { useAuth } from '../components/AuthContext';
import { useAppContext } from '../components/AppContext.js';

import api from "../classes/API.js";

/**
 * Custom hook to handle chat functionality, including sending messages,
 * receiving responses, and managing chat state.
 *
 * @param {Array} initialMessages - Initial messages to populate the chat.
 * @param {string} token - Authentication token for API requests.
 * @param {boolean} tokenVerificationCompleted - Flag indicating if token verification is complete.
 * @param {Object} messageEndRef - Ref to the end of the message list for auto-scrolling.
 * @returns {Object} - An object containing chat state and functions for handling chat interactions.
 */
export const useChat = (initialMessages, messageEndRef, advisor, currentConsultationId, onLastMessageDelete) => {
    const [isLoading, setIsLoading] = useState(false);
    const [callAPI, setCallAPI] = useState("stop");
    const [answerText, setAnswerText] = useState("");
    const [jsonOptions, setJsonOptions] = useState("");
    const [chatMessages, setChatMessages] = useState(initialMessages);
    const [consultationId, setConsultationId] = useState(null);
    const [totalCost, setTotalCost] = useState(0.0);
    const { currentProfileId, officeManager } = useAppContext();
    const isMountedRef = useRef(true);

    const { token, tokenVerificationCompleted } = useAuth();

    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false; };
    }, []);

    // Trigger API call if token is available and verified
    useEffect(() => {
        if (token && tokenVerificationCompleted && advisor && !currentConsultationId) {
            // add consultant
            console.log('Add initial consultant:', advisor);
            addChatMessage('joined', 'Consultant joined the chat', { data: { speciality: advisor, goal: "" } });
            wrapperCallAPI();
        }
    }, [token, tokenVerificationCompleted, currentConsultationId, advisor]);

    useEffect(() => {
        if (initialMessages) {

            const messagesWithoutLast = initialMessages.slice(0, -1);
            const lastMessage = initialMessages[initialMessages.length - 1];

            if (lastMessage && lastMessage.role === "assistant") {

                const { cleanedMessage, jsonPart } = extractJson(lastMessage.content);

                if (isMountedRef.current) {
                    setJsonOptions(jsonPart);
                    const newMessages = [
                        ...messagesWithoutLast,
                        { role: "assistant", content: cleanedMessage },
                    ];
                    setChatMessages(newMessages);
                    scrollToBottom();
                }
            }
            else {
                setChatMessages(initialMessages);
            }

        }
    }, [initialMessages]);

    // Handle API call state based on callAPI variable
    useEffect(() => {
        switch (callAPI) {
            case "run":
                CallAPI(answerText, currentConsultationId, advisor);
                break;
            case "clear":
                setChatMessages([]);
                break;
            case "stop":
                break;
            default:
                break;
        }
    }, [callAPI]);

    /**
     * Wrapper function to set the mode and answer text for API call.
     *
     * @param {string} mode - Mode for the API call ("run", "clear", "stop").
     * @param {string} answer - The answer text to send to the API.
     */
    const wrapperCallAPI = (mode = "run", answer = "") => {
        setAnswerText(answer);
        setCallAPI(mode);
    };

    /**
    * Wrapper function to stop the current API call and start a new one after a delay.
    *
    * @param {string} mode - Mode for the API call ("run", "clear", "stop").
    * @param {string} answer - The answer text to send to the API.
    */
    const wrapperStopAndCallAPI = (mode = "run", answer = "") => {
        wrapperCallAPI("stop");
        setTimeout(() => { wrapperCallAPI(mode, answer); }, 500);
    };

    /**
     * Function to send an answer message and initiate the API call process.
     *
     * @param {string} message - The message text to send.
     */
    const sendAnswer = (message) => {
        //if (!message || !message.trim()) return;
        console.log("sendAnswer:", message);
        setJsonOptions("");
        wrapperStopAndCallAPI("run", message);
    };

    /**
     * Fetch the chat response from the API.
     *
     * @param {string} messagebody - The message body to send.
     * @param {string} timeZone - The current time zone.
     * @param {Date} currentDateTime - The current date and time.
     * @returns {Object} - The API response.
     */
    const fetchChatResponse = async (messagebody, timeZone, currentDateTime, consId, advisor) => {
        //console.log('office:', officeManager.getCurrentOfficeId());
        //console.log('advisor:', advisor);
        const response = await api.apiGetChatResponse({
            //FIXME: PROFILE ID
            profileId: currentProfileId,
            officeId: officeManager.getCurrentOfficeId(),
            topic: 'topic',
            //consultation: JSON.stringify(messagebody),
            consultationId: consId,
            message: messagebody,
            timezone: timeZone,
            datetime: currentDateTime,
            advisor: advisor,
            language: "en",
        }, token);
        return response;
    };

    /**
   * Read and process the response stream from the API.
   *
   * @param {ReadableStreamDefaultReader} reader - The stream reader.
   * @param {Array} newMessage - The new message array to update.
   */
    const readResponseStream = async (reader, newMessage) => {
        let messagetext = "";
        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            //console.log("Response:", value);
            try {
                const data = JSON.parse(value);

                if (data?.isServiceMessage && data.messageType === "set-consultation-id") {
                    //console.log('Service message:', data);
                    setConsultationId(data.consultationId);
                    return;
                }

                if (data?.isServiceMessage && data.messageType === "total-cost-by-consultation-id") {
                    //console.log('Service message:', data);
                    setTotalCost(parseFloat(data.cost));
                    return;
                }

            } catch {
                // Ignore JSON parse errors
            }

            messagetext += value;
            const { cleanedMessage, jsonPart } = extractJson(messagetext);

            if (isMountedRef.current) {
                setJsonOptions(jsonPart);
                const newMessages = [
                    ...newMessage,
                    { role: "assistant", content: cleanedMessage },
                ];
                setChatMessages(newMessages);
                scrollToBottom();
            }
        }
    };

    /**
     * Function to call the API and process the chat response.
     *
     * @param {string} answerText - The text to send as the user's answer.
     */
    const CallAPI = useCallback(async (answerText, consId, advisor) => {
        const doCallAPI = async () => {
            try {
                setIsLoading(true);
                const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
                const currentDateTime = new Date();
                let newMessage = chatMessages;

                if (answerText) newMessage = [...chatMessages, { role: "user", content: `${answerText}` }];
                setChatMessages(newMessage);
                scrollToBottom();

                //const filtered = filterMessages(newMessage);
                const messagebody = JSON.stringify(newMessage);
                const response = await fetchChatResponse(messagebody, timeZone, currentDateTime, consId, advisor);

                if (!response.ok) {
                    throw new Error(`Server responded with status: ${response.status}`);
                }

                const reader = response.body
                    .pipeThrough(new TextDecoderStream("utf-8"))
                    .getReader();

                await readResponseStream(reader, newMessage);
                setCallAPI("stop");
            } catch (error) {
                if (isMountedRef.current) {
                    console.error("Failed to fetch:", error);
                    setCallAPI("stop");
                    setIsLoading(false);
                }
            } finally {
                if (isMountedRef.current) {
                    setIsLoading(false);
                    setCallAPI("stop");
                }
            }
        };

        doCallAPI();
    }, [chatMessages, token]);


    /**
    * Handle the deletion of the last user message.
    * Removes the last user message from the chat messages array.
    */
    const handleDeleteLastUserMessage = () => {
        const lastUserMessageIndex = chatMessages.reduce((acc, message, index) => message.role === "user" ? index : acc, -1);

        if (lastUserMessageIndex !== -1) {
            const newChatMessages = chatMessages.filter((_, index) => index < lastUserMessageIndex);
            setChatMessages(newChatMessages);
            setJsonOptions("");
            saveMessages(newChatMessages);
            if (onLastMessageDelete) onLastMessageDelete();
        }
    };

    const addChatMessage = (role, message, data = {}, save = false) => {
        setChatMessages((prevChatMessages) => {
            const newMessage = [...prevChatMessages, { role: role, content: `${message}`, ...data }];
            if (save) {
                saveMessages(newMessage);
            }
            return newMessage;
        });
        scrollToBottom();
    }

    const saveMessages = async (messages) => {
        //console.log("Saving messages:", messages);
        const messagebody = JSON.stringify(messages);
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const currentDateTime = new Date();
        const response = await api.apiSaveChatMessages({
            //FIXME: PROFILE ID
            profileId: currentProfileId,
            officeId: officeManager.getCurrentOfficeId(),
            topic: 'topic',
            //consultation: JSON.stringify(messagebody),
            consultationId: currentConsultationId,
            message: messagebody,
            timezone: timeZone,
            datetime: currentDateTime,
            advisor: advisor,
            language: "en",
        }, token);
        return response;
    }

    const removeMessagesByRole = (role) => {
        setChatMessages((prevChatMessages) => {
            //console.log("Previous chat messages:", prevChatMessages);

            const newChatMessages = prevChatMessages.filter((message) => {
                if (message.role !== role) {
                    return true;
                } else {
                    //console.log(`Message with role ${role} removed:`, message);
                    return false;
                }
            });

            //console.log("New chat messages:", newChatMessages);
            return newChatMessages;
        });
        setJsonOptions("");
        scrollToBottom();
    }

    /**
    * Handle the selection of an option.
    * Sends the selected option as an answer message.
    *
    * @param {string} optionValue - The selected option value.
    */
    const handleOptionSelect = (optionValue) => {
        if (!optionValue.trim()) return;
        sendAnswer(optionValue);
    };

    /**
     * Scroll to the bottom of the chat message list.
     * Ensures the latest message is visible in the view.
     */
    const scrollToBottom = () => {
        setTimeout(() => messageEndRef?.current?.scrollIntoView({ behavior: 'smooth' }), 200);
    };

    return {
        chatMessages,
        isLoading,
        jsonOptions,
        sendAnswer,
        handleDeleteLastUserMessage,
        handleOptionSelect,
        scrollToBottom,
        consultationId,
        totalCost,
        addChatMessage,
        removeMessagesByRole,
    };
};
