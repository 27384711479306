// WelcomeScreen.tsx
import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import logo from '../img/wixee-magistral.svg';
import { preloadImages } from '../utils/utils';
import { useAuth } from '../components/AuthContext';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../config/AppModes.ts';

interface WelcomeScreenProps {
    setDirection: (direction: 'forward' | 'backward') => void;
    useDelayedNavigation: () => (navigationFn: () => void, path: string) => void;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ setDirection, useDelayedNavigation }) => {
    const setDelayedNavigation = useDelayedNavigation();

    const { isAuthenticated, tokenVerificationCompleted } = useAuth();
    const { t } = useTranslation();


    useEffect(() => {
        const images = [
            require('../img/splash/splash1bkg.webp'),
            require('../img/splash/splash2bkg.webp'),
            require('../img/splash/splash3bkg.webp'),
        ];
        preloadImages(images); // Preload images on component mount
    });

    useEffect(() => {
        if (tokenVerificationCompleted) {

            const timeoutId = setTimeout(() => {
                if (isAuthenticated) {
                    setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_HOME.path);
                } else {
                    setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_SPLASH1.path);
                }
            }, 500);

            return () => clearTimeout(timeoutId); // Clear timeout if component unmounts
        }
    }, [setDelayedNavigation, setDirection, isAuthenticated, tokenVerificationCompleted]);

    return (
        <Box sx={{
            // background: 'linear-gradient(to bottom, #7838FF, #1F0061)',
            background: 'rgba(255,255,255,0.5)',
            width: '100%',
            height: '100svh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 0, m: 0,
            overflow: 'hidden',
        }}>
            <Box sx={{ pt: 20, pb: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={logo} alt="Wixee Logo" style={{ width: '20rem', marginBottom: '16px' }} />
                {/* <Typography sx={{ pl: 8, pr: 8, textAlign: 'center', color: 'white', maxWidth: '24rem' }} variant="h1">
                    {t('welcome.title')}
                </Typography> */}
            </Box>
        </Box>
    );
};

export default WelcomeScreen;
