import React, { useEffect, useRef } from 'react';
import { Box, Select, MenuItem, FormControl } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext';  // Import useAuth from AuthContext
import { List, ListItem, ListItemIcon, TextField, InputLabel } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import HelpIcon from '@mui/icons-material/Help';

import LanguageIcon from '@mui/icons-material/Language';
import ConsultantIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import { useAuth } from '../components/AuthContext'; // Import useAuth hook
import { useAppContext } from '../components/AppContext.js'; // Import useAppContext hook
import { useTranslation } from 'react-i18next';

export function SettingsScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);

    const { t } = useTranslation();
    const { userProfile } = useAuth();

    const [language, setLanguage] = React.useState('en');
    const [office, setOffice] = React.useState('1');
    const { officeManager } = useAppContext();
    const allOffices = officeManager.getAllOffices();


    const handleChange = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <Box sx={{
            pt: 4, pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',
            height: '100svh', width: '100%',
            background: 'rgba(255, 255, 255, 0.7)',
        }}>

            <Box sx={{
                display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', mt: 0,
            }}>

                <Box
                    sx={{
                        width: '90%',
                        borderRadius: '20px 20px 0 0',
                        textAlign: 'center',
                        padding: 2,
                        position: 'relative',
                    }}
                >
                    <FormControl variant="standard" sx={{ m: 0, minWidth: 120, width: '100%' }}>
                        <List component="nav" sx={{ mt: 1 }}>
                            <ListItem>

                                <ListItemIcon>
                                    <LanguageIcon />
                                </ListItemIcon>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>{t('settings.selectLanguage')}</InputLabel>
                                    <Select
                                        label={t('settings.language')}
                                        value={language}
                                        onChange={handleChange}
                                        displayEmpty
                                        fullWidth
                                        required
                                        inputProps={{ 'aria-label': 'Language' }}
                                        style={{ marginLeft: 'auto' }} // Adjust the position of the select box
                                    >
                                        <MenuItem value="" disabled>
                                            {t('settings.selectLanguage')}
                                        </MenuItem>
                                        <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="es">Spanish</MenuItem>
                                        <MenuItem value="fr">French</MenuItem>
                                        <MenuItem value="de">German</MenuItem>
                                        {/* Add more languages as needed */}
                                    </Select>
                                </FormControl>
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <ConsultantIcon />
                                </ListItemIcon>

                                <FormControl fullWidth variant="standard">
                                    <InputLabel>{t('settings.defaultOffice')}</InputLabel>
                                    <Select
                                        value={office}
                                        onChange={handleChange}
                                        displayEmpty
                                        fullWidth
                                        required
                                        inputProps={{ 'aria-label': 'Language' }}
                                        style={{ marginLeft: 'auto' }} // Adjust the position of the select box
                                    >
                                        <MenuItem value="" disabled>
                                            {t('settings.defaultOffice')}
                                        </MenuItem>

                                        {allOffices.map((office, index) => (
                                            <MenuItem value={index + 1} key={office.title} >{t(office.title)}</MenuItem>
                                        ))}
                                        {/* Add more languages as needed */}
                                    </Select>
                                </FormControl>
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                {/* email input here */}
                                <TextField
                                    variant="standard"
                                    margin="dense"
                                    required
                                    fullWidth
                                    id="email"
                                    label={t('settings.emailAddress')}
                                    name="email"
                                    autoComplete="email"
                                    value={userProfile?.email}
                                    disabled
                                />
                            </ListItem>

                            <ListItem >
                                <ListItemIcon>
                                    <PaymentIcon />
                                </ListItemIcon>
                                <TextField
                                    variant="standard"
                                    margin="dense"
                                    fullWidth
                                    id="item2"
                                    label="Item 2"
                                    name="item2"
                                    autoComplete=""
                                />

                            </ListItem>

                            <ListItem >
                                <ListItemIcon>
                                    <HelpIcon />
                                </ListItemIcon>
                                <TextField
                                    variant="standard"
                                    margin="dense"
                                    fullWidth
                                    id="item3"
                                    label="Item 3"
                                    name="item3"
                                    autoComplete=""

                                />

                            </ListItem>

                        </List>
                    </FormControl>

                </Box>

            </Box>

        </Box>

    );
}

export default SettingsScreen;