
export const preloadImages = (images) => {
    images.forEach((image) => {
        const img = new Image();
        img.src = image;
    });
};

/**
 * Function to extract a JSON object from a given input message.
 * The function identifies and extracts the first JSON object found in the message,
 * removes the JSON object from the message, and cleans up any Markdown formatting.
 * It returns an object containing the cleaned message and the extracted JSON part.
 *
 * @param {string} inputMessage - The input message containing a JSON object and other text.
 * @returns {Object} - An object with two properties:
 *                     - cleanedMessage: The input message with the JSON part removed and formatting cleaned.
 *                     - jsonPart: The extracted JSON object as a string.
 */
export function extractJson(inputMessage) {
    let depth = 0;
    let jsonPart = '';
    let inJson = false;
    let startIndex = 0;

    for (let i = 0; i < inputMessage.length; i++) {
        const char = inputMessage[i];

        if (char === '{') {
            depth++;
            if (!inJson) {
                inJson = true;
                startIndex = i;
            }
        } else if (char === '}') {
            depth--;
        }

        if (inJson && depth === 0) {
            jsonPart = inputMessage.substring(startIndex, i + 1);
            break;
        }
    }

    if (inJson && depth > 0) {
        jsonPart = inputMessage.substring(startIndex);
    }

    const cleanedMessage = inputMessage
        .replace(jsonPart, '')
        .replace('```json', '')
        .replace('JSON:', '')
        .replace('JSON', '')
        .replace('```', '')
        .trim();

    return { cleanedMessage, jsonPart };
}

export function safeParseJson(json) {
    try {
        return JSON.parse(json);
    } catch (error) {
        return null;
    }
}

export function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
}

