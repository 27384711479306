import { useEffect, useState } from "react";
import { Grid, Fade, Box, Typography, CardActionArea, CardContent } from '@mui/material';

const OptionButtons = ({ jsonString, onOptionSelect, scrollToBottom }) => {

    const maxOptions = 6;

    const [optionList, setOptionList] = useState([]);
    const [isVisible, setIsVisible] = useState(true);

    function detectOptions(msg) {
        const options = detectOptions1(msg);
        if (options.option_list.length > 0) {
            return options;
        }
        return detectOptions2(msg);
    }

    function detectOptions1(msg) {
        //console.log("detecting options: ", msg);
        // Regex pattern to match content within {} and account for an unfinished option at the end
        //console.log("detect:", msg);
        const regex = /"text":\s?"(.*?)(?="|$)/g;
        let match;
        const results = [];

        // Using regex.exec in a loop to find all matches in the message
        while ((match = regex.exec(msg)) !== null) {
            // Adding the matched option to the results array
            results.push(match[1]);
        }

        //console.log("detect:", results);
        return { option_list: results.slice(0, maxOptions) };
    }

    function detectOptions2(msg) {
        // Step 1: Extract the content inside the "options_list" brackets
        const listRegex = /"options_list"\s*:\s*\[\s*((?:.|\s)*)\s*$/;
        let listMatch = listRegex.exec(msg);
        let optionsContent = listMatch ? listMatch[1] : '';

        // Step 2: Scan each option individually
        const optionRegex = /"([^"]*)"/g;
        const results = [];
        let optionMatch;

        while ((optionMatch = optionRegex.exec(optionsContent)) !== null) {
            results.push(optionMatch[1]);
        }

        // If the optionsContent ends with an unclosed quote, handle the incomplete option
        const unclosedQuoteIndex = optionsContent.lastIndexOf('"');
        if (unclosedQuoteIndex !== -1 && unclosedQuoteIndex === optionsContent.length - 1) {
            // Handle the incomplete option if necessary
            // For now, we're ignoring it
            // Optionally, you could add custom handling logic here
        }

        // Limit the results to a maximum number of options if needed
        const maxOptions = 10; // You can adjust the maxOptions as needed
        return { option_list: results.slice(0, maxOptions) };
    }


    useEffect(() => {
        try {
            //console.log("jsonString", jsonString);
            const jsonData = detectOptions(jsonString);
            //console.log("jsonData", jsonData);
            //const jsonData = JSON.parse(jsonString);
            if (jsonData.option_list) {
                //console.log("set options", jsonData.option_list)
                setTimeout(() => { setOptionList(jsonData.option_list); }, 1000);
            }
        } catch (e) {
            //console.error(e);
            // Reset optionList to empty if jsonString is not valid
            setOptionList([]);
        }
    }, [jsonString]); // Dependency array, re-run effect if jsonString changes

    const handleClick = (option, index) => {
        // Call the callback function with the selected option
        onOptionSelect(option, index);
    };

    // Ensure we handle the case when there are no options to display
    if (!isVisible || !jsonString) {
        //console.log("not visible?", isVisible)
        return null;
    }

    return (
        <Grid container spacing={1} sx={{ pb: 2, background: 'white' }}>
            {optionList.map((option, index) => (
                <Grid item xs={12} sm={12} lg={12} key={index}> {/* This will make it full width on small screens and half-width (two columns) on medium screens and up */}
                    <Fade in={true} timeout={1000}>

                        <Box sx={{
                            height: '100%', width: 'auto', justifyContent: 'right', display: 'flex',
                            flexDirection: 'row', alignItems: 'flex-start',
                        }}>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                minHeight: '40px', width: 'auto', textAlign: 'left',
                                minWidth: '50%',
                                maxWidth: '70%', mr: 2,
                                borderRadius: '8px 0px 8px 8px', overflow: 'hidden',
                                border: '3px solid #E5E7EB',
                            }}>
                                <CardActionArea onClick={() => { handleClick(option, index) }}>
                                    <CardContent sx={{ p: 1 }}>
                                        <Typography sx={{ p: 0, width: '100%', textAlign: 'center' }} variant="body2">
                                            {option}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>

                            </Box>
                            {scrollToBottom()}

                        </Box>

                    </Fade>
                </Grid>
            ))}
        </Grid >
    );
}


export default OptionButtons;