import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBack';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import GoogleLoginButton from '../components/GoogleLoginButton.js';
import logo from '../img/wixee-magistral.svg';
import { AppRoutes } from '../config/AppModes.ts';

export function LoginScreen({ setDirection, useDelayedNavigation }) {
    const setDelayedNavigation = useDelayedNavigation();

    const handleGoogleLogin = () => {
        // Implement Google login logic here
        console.log('Login with Google');
        setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_HOME.path);
    };

    const handleGoogleLoginCode = (code) => {
        // Implement Google login logic here
        console.log('Login with Google');
        console.log('handleGoogleLoginCode:', code);
        // check if code is string
        if (typeof code === 'string') {
            setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_GOOGLELOGIN.path + '?code=' + code);
        }
        else {
            console.log('code is not a string!');
        }
    };

    const handleFacebookLogin = () => {
        // Implement Facebook login logic here
        console.log('Login with Facebook');
    };

    const handleAppleLogin = () => {
        // Implement Apple login logic here
        console.log('Login with Apple');
    };

    const handleEmailLogin = () => {
        // Navigate to LoginByEmailScreen
        setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_LOGIN_BY_EMAIL.path);
    };

    return (
        <Box sx={{
            // background: 'linear-gradient(to bottom, #7838FF, #1F0061)',
            height: '100vh',
            display: 'flex',
            flex: 1,
            flexDirection: 'column', padding: 0
        }}>
            <Box sx={{ background: 'rgba(255,255,255,0.5)', height: '100%' }}>
                <Box
                    sx={{
                        pt: 10,
                        pb: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'black',
                        height: 'auto',
                    }}
                >

                    <img src={logo} alt="Wixee Logo" style={{ width: '20rem' }} />

                    <Typography sx={{ mx: 4, mt: 4, textAlign: 'center' }} variant="h2">Welcome!</Typography>
                    <Typography sx={{ mx: 4, mt: 1, textAlign: 'left' }} variant="body1">Signin to continue</Typography>



                    <Box sx={{ mt: 2, mx: 4, pt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        <GoogleLoginButton onSuccess={handleGoogleLoginCode} onFailure={handleGoogleLoginCode} />

                        <Button
                            variant="outlined"
                            startIcon={<FacebookIcon style={{ fontSize: 30 }} />}
                            onClick={handleFacebookLogin}
                            sx={{
                                mb: 2, width: '100%',  // Ensures text and icon are aligned more to the left
                                justifyContent: 'flex-start',  // Ensures text and icon are aligned more to the left
                                '.MuiButton-startIcon': {      // Target the startIcon specifically for any additional styling
                                    marginRight: 2,           // Adjust spacing between the icon and the text
                                },
                            }}
                        >
                            Login with Facebook
                        </Button>

                        <Button
                            variant="outlined"
                            startIcon={<AppleIcon style={{ fontSize: 30 }} />}
                            onClick={handleAppleLogin}
                            sx={{
                                mb: 2, width: '100%',  // Ensures text and icon are aligned more to the left
                                justifyContent: 'flex-start',  // Ensures text and icon are aligned more to the left
                                '.MuiButton-startIcon': {      // Target the startIcon specifically for any additional styling
                                    marginRight: 2,           // Adjust spacing between the icon and the text
                                },
                            }}
                        >
                            Login with Apple
                        </Button>

                        <Button
                            variant="outlined"
                            startIcon={<EmailIcon style={{ fontSize: 30 }} />}
                            onClick={handleEmailLogin}
                            sx={{
                                mb: 2, width: '100%',  // Ensures text and icon are aligned more to the left
                                justifyContent: 'flex-start',  // Ensures text and icon are aligned more to the left
                                '.MuiButton-startIcon': {      // Target the startIcon specifically for any additional styling
                                    marginRight: 2,           // Adjust spacing between the icon and the text
                                },
                            }}
                        >
                            Login with Email
                        </Button>

                        {/* <Box sx={{ mt: 2 }}>
                            <Button variant="outlined" color="secondary"
                                onClick={() => setDelayedNavigation(() => setDirection('backward'), AppRoutes.ROUTE_SPLASH4.path)} sx={{ mr: 1 }}>
                                <ArrowBackIosIcon />
                            </Button>

                            </Box> */}
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}

export default LoginScreen;