import React, {
    FC
} from'react';
import {
    Typography
    , Card
    , CardActionArea
    , CardContent
    , Box
    , Fade
} from '@mui/material';
import {
    ProfileIcon
} from '../components/ProfileIcon.tsx';

interface IProfile {
    id?: bigint
    , name: string
    , comment: string
    , height: Number
    , weight: Number
    , date_of_birth: Date
};

type TProfileCard = FC<{
    profile: IProfile
    , handleCardClick: (p: IProfile) => void
    , ind: number
}>;

export const ProfileCard: TProfileCard = ({
    profile
    , handleCardClick
    , ind
}) => (
    <Card key={profile.id} sx={{
        overflow: 'hidden', p: 0, mb: 0,
        borderRadius: '11px',
        animation: `fadeInUp 0.5s ease-in-out ${ind * 0.2}s forwards`,
        opacity: 0,
        transform: 'translateY(20px)',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',

    }} elevation={0}>
        <CardActionArea onClick={() => { handleCardClick(profile) }}>
            <CardContent sx={{ p: 0 }}>
                <Fade in={true} timeout={500}>
                    <Box sx={{
                                width: '100%', // aspectRatio: '3/4',
                                borderRadius: '11px',
                        justifyContent: 'center',
                        position: 'relative', overflow: 'hidden',
                    }}>
                        {/* Gradient overlay */}
                        <Box sx={{
                            position: 'absolute', // Position over the image
                            width: '100%', // Cover entire area
                            height: '100%', // Cover entire area
                            background: 'linear-gradient(to bottom, #C7E1FA, rgba(255,255,255,0))', // Gradient from left to right
                        }} />
                        <Box sx={{
                            height: '100%', width: '100%', justifyContent: 'center', display: 'flex',
                                    flexDirection: 'column', alignItems: 'center', paddingTop: '10%', paddingBottom: '10%'
                        }}>
                            <Box sx={{ width: '60%', aspectRatio: '1/1' }}>
                                <ProfileIcon
                                    id={profile.id || 0}
                                    sx={{
                                        padding: 0
                                        , height: '100%'
                                        , width: '100%'
                                        , zoom: 3
                                    }}
                                />
                            </Box>
                                    <Typography sx={{
                                        p: 1, width: '80%',
                                        textAlign: 'center',
                                        position: 'relative',
                                        wordWrap: 'break-word', // Old CSS3 property, use as a fallback
                                        wordBreak: 'break-word', // Ensures that the words can be broken and wrapped at arbitrary points if needed
                                        overflowWrap: 'break-word', // Standard property to break words to prevent overflow
                                        hyphens: 'auto' // Automatically add hyphens when breaking words (works best in supported languages)                                    
                                    }} variant="h3">
                                {profile.name}
                            </Typography>
                            <Typography sx={{ p: 0, width: '80%', textAlign: 'center', position: 'relative', }} variant="body1">
                                {profile.comment}
                            </Typography>
                        </Box>
                    </Box>
                </Fade>
            </CardContent>
        </CardActionArea>
    </Card>
);

export default ProfileCard;