// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Language resource files
import en from './locales/en.json';
import ru from './locales/ru.json';

i18n
    .use(LanguageDetector) // Use the language detector plugin
    .use(initReactI18next) // Pass the i18n instance to react-i18next
    .init({
        resources: {
            en: {
                translation: en
            },
            ru: {
                translation: ru
            },
        },
        fallbackLng: 'en', // Fallback language
        interpolation: {
            escapeValue: false // React already escapes by default
        },
        detection: {
            // Order and from where user language should be detected
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

            // Keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // Cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // Languages to not persist (cookie, localStorage)

            // Optional expire and domain for set cookie
            cookieMinutes: 10,
            cookieDomain: 'myDomain',

            // Optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement,

            // Only detect languages that are in the whitelist
            checkWhitelist: true
        },
        whitelist: ['en', 'ru'], // List of allowed languages
        debug: true // Enable debug mode to see detection logs in the console
    });

export default i18n;
