
import React, { useState, useRef, useEffect } from 'react';
import { Button, Box, Typography, TextField, Grid, Collapse } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../components/AuthContext';  // Import useAuth from AuthContext
import { AppRoutes } from '../config/AppModes.ts';
import { useAppContext } from '../components/AppContext.js';

export function LoginByEmailScreen({ setDirection, useDelayedNavigation }) {
    const setDelayedNavigation = useDelayedNavigation();

    const [code, setCode] = useState(Array(4).fill(''));
    const [isCodeSent, setIsCodeSent] = useState(false);
    const inputRefs = useRef([]);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [authError, setAuthError] = useState(false);
    const { showAlert } = useAppContext();

    const { sendCode, verifyCode } = useAuth();  // Get authentication status

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    //NOTE: Do not validate on each letter
    useEffect(
        () => {
            setEmailError(false);
            const keyboardTimeout = setTimeout(() => {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                setEmailError(email && !emailPattern.test(email));
            }, 500);
            return () => clearTimeout(keyboardTimeout);
        }
        , [email]
    );

    const handleSendCode = async () => {
        // Logic to send code to the email
        console.log('Sending code to:', email);
        const isSent = await sendCode(email);
        if (isSent) {
            setIsCodeSent(true);
            setAuthError(false);
        } else {
            showAlert('error', 'Code cannot be sent. Please try again later.');
            console.error('Error sending code');
            setIsCodeSent(false);
            setAuthError(true);
        }

    };


    const handleVerifyCode = async () => {
        // Logic to send code to the email
        const isVerified = await verifyCode(code.join(''));
        setAuthError(!isVerified);
        if (isVerified) {
            setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_HOME.path);
        } else {
            setCode(Array(4).fill(''));
        }
    };

    const handleInput = (index, event) => {
        const { value } = event.target;
        const upperValue = value.toUpperCase();

        if (upperValue.length === 1) {
            const newCode = [...code];
            newCode[index] = upperValue;
            setCode(newCode);
            if (index < 3) {
                inputRefs.current[index + 1].focus();
            }
        } else if (upperValue.length > 1) {
            const previousValue = code[index];
            const newChar = upperValue[0] === previousValue ? upperValue[1] : upperValue[0];
            const newCode = [...code];
            newCode[index] = newChar;
            setCode(newCode);
            if (index < 3) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        const { key } = event;

        if (key === 'Backspace') {
            event.preventDefault();
            const newCode = [...code];
            newCode[index] = '';
            setCode(newCode);
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        } else if (key === 'ArrowLeft' && index > 0) {
            inputRefs.current[index - 1].focus();
        } else if (key === 'ArrowRight' && index < 3) {
            inputRefs.current[index + 1].focus();
        }
    };

    return (
        <Box sx={{
            background: 'linear-gradient(to bottom, #7838FF, #1F0061)',
            height: '100vh',
            display: 'flex',
            flex: 1,
            flexDirection: 'column', padding: 0,
            overflow: 'hidden',
        }}>
            <Box sx={{
                background: 'white',
                height: '100%',
            }}>
                <center>
                    {/* <img src={logo} alt="Wixee Logo" style={{ width: '100px', margin: '30px' }} /> */}
                    <Typography sx={{ mx: 4, mt: 4, textAlign: 'left' }} variant="h1">Welcome!</Typography>
                </center>

                <Box sx={{ mx: 4, pt: 0, pb: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    {/* <Typography sx={{ mx: 4, mt: 2, width: '100%', textAlign: 'left' }} variant="h3">Step 1 </Typography> */}
                    <Typography sx={{ mx: 4, mt: 1, mb: 1, width: '100%', textAlign: 'left' }} variant="body1">
                        Enter your email. We will send you a code to sign in.
                    </Typography>
                    <Box sx={{ width: '100%', mb: 2, mt: 1 }}>
                        <TextField
                            label="✉️ e-mail"
                            variant="outlined"
                            fullWidth
                            sx={{
                                mb: 2, '& .MuiOutlinedInput-root': {
                                    borderRadius: '100px',
                                },
                            }}
                            value={email}
                            onChange={handleEmailChange}
                            error={emailError}
                            helperText={emailError ? 'Please enter a valid email address.' : ''}
                        />
                        <Button
                            sx={{
                                width: '12rem',
                                mt: 1,
                                // Disabled state styles
                                '&.Mui-disabled': {
                                    backgroundColor: '#DDDDDD',
                                    color: '#AAAAAA',
                                },
                            }}
                            variant={(emailError || email === '') ? "outlined" : isCodeSent ? "outlined" : "contained"} color="primary"
                            disabled={emailError || email === ''}
                            onClick={handleSendCode}
                        >
                            Send Code
                        </Button>
                    </Box>


                    <Collapse in={isCodeSent} timeout={300}>
                        {/* <Typography sx={{ mx: 0, mt: 4, width: '100%', textAlign: 'left' }} variant="h3">Step 2</Typography> */}
                        <Typography sx={{ mx: 0, mt: 1, mb: 1, width: '100%', textAlign: 'left' }} variant="body1">
                            Enter the code sent to your email.
                        </Typography>

                        <Box sx={{ width: '100%', mb: 2 }}>
                            <Grid container spacing={2} sx={{ width: '100%' }}>
                                {code.map((digit, index) => (
                                    <Grid item xs={3} key={index}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{
                                                maxLength: 2,
                                                style: {
                                                    textAlign: 'center',
                                                    fontSize: '2.5rem', // Set font size to be bigger
                                                    fontWeight: 'bold', // Set font weight to bold
                                                    color: 'rgb(60, 0, 139)'
                                                }
                                            }}
                                            value={digit}
                                            onInput={(e) => handleInput(index, e)}
                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                            inputRef={(el) => inputRefs.current[index] = el}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Collapse in={authError} timeout={300}>
                                <Typography sx={{ mx: 0, mt: 1, mb: 1, width: '100%', textAlign: 'left' }}
                                    variant="body2"
                                    color="error"
                                >Login is not possible, entered code does not match with code sent to email.</Typography>
                            </Collapse>
                            <Button
                                sx={{
                                    mt: 2,
                                    width: '12rem',
                                    '&.Mui-disabled': {
                                        backgroundColor: '#DDDDDD',
                                        color: '#AAAAAA',
                                    },
                                }}
                                color="primary"
                                variant={(code.some(digit => digit === '')) ? "outlined" : "contained"}
                                disabled={code.some(digit => digit === '')}
                                onClick={handleVerifyCode}
                            >
                                Verify Code
                            </Button>
                        </Box>

                    </Collapse>

                    <Box sx={{ mt: 2, width: '100%' }}>
                        <Button variant="outlined" color="secondary" sx={{ mt: 2 }}
                            onClick={() => setDelayedNavigation(() => setDirection('backward'), -1)}>
                            <ArrowBackIosIcon />
                        </Button>

                    </Box>
                </Box>
            </Box>
        </Box >
    );
}

export default LoginByEmailScreen;