import React, { useEffect } from 'react';
import { Box, Typography, Card, CardContent, CardActionArea } from '@mui/material';
import splash2bkg from '../img/splash/splash2bkg.webp';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForward';
import PageIndicator from '../elements/PageIndicator.tsx';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../config/AppModes.ts';

function SplashScreen2({ setDirection, useDelayedNavigation }) {
    const setDelayedNavigation = useDelayedNavigation();

    const { t } = useTranslation();

    return (
        <Box sx={{
            background: 'linear-gradient(to bottom, #7838FF, #1F0061)',
            height: '100svh',
            display: 'flex',
            flex: 1,
            flexDirection: 'column', padding: 0,
            overflow: 'hidden'
        }}>
            <Box sx={{ background: 'linear-gradient(to bottom, #7838FF, #1F0061)', height: '100%' }}>

                <Box
                    sx={{
                        backgroundImage: `url(${splash2bkg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >

                    <Box
                        sx={{
                            width: '70%',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: '2rem 2rem 0 0',
                            textAlign: 'left',
                            color: 'black',
                            padding: 3,
                            backdropFilter: 'blur(5px)',
                            position: 'absolute',
                            bottom: 0,
                        }}
                    >

                        <Typography sx={{ mt: 1, mb: 2 }} variant="h2">{t('splash2.title')}</Typography>
                        <Typography sx={{ mt: 2, mb: 4 }} variant="body1">{t('splash2.description')}</Typography>
                        <Box sx={{ ml: 1, mr: 1, mb: 1, mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                            <Card sx={{ p: 0, mb: 0, borderRadius: '10rem', width: 'fit-content', aspectRatio: 1 }} elevation={0}>
                                <CardActionArea onClick={() => { setDelayedNavigation(() => setDirection('backward'), AppRoutes.ROUTE_SPLASH1.path) }}>
                                    <CardContent sx={{ p: 2 }}>
                                        <ArrowBackIosIcon />
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Box> <PageIndicator page={2} />  </Box>
                            <Card sx={{ p: 0, mb: 0, borderRadius: '10rem', width: 'fit-content', aspectRatio: 1, color: 'white', backgroundColor: theme => theme.palette.primary.main }} elevation={0}>
                                <CardActionArea onClick={() => { setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_SPLASH3.path) }}>
                                    <CardContent sx={{ p: 2 }}>
                                        <ArrowForwardIosIcon />
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default SplashScreen2;