import React, { useState, useEffect } from 'react';
import { Box, Slide } from '@mui/material';
import { useAppContext } from '../components/AppContext.js'; // Import useAppContext hook
import useViewportHeight from '../elements/ViewPortHeight.js';
import FooterStandard from './FooterStandard';
import FooterConsultation from './FooterConsultant';
import { footerTypes } from '../config/AppModes.ts';
import { SafeArea } from 'capacitor-plugin-safe-area';


const Footer = ({ setDirection, useDelayedNavigation }) => {
    const [isStandaloneIOS, setIsStandaloneIOS] = useState(false);
    const { footerVisibility, footerType } = useAppContext();
    const { isKeyboardVisible } = useViewportHeight();
    const [safeAreaInsets, setSafeAreaInsets] = useState({ top: 0, bottom: 0, left: 0, right: 0 });

    useEffect(() => {
        detectIosBottomPadding(isKeyboardVisible);
    }, [isKeyboardVisible]);

    useEffect(() => {
        console.log('isNativePlatform?', window.Capacitor.isNativePlatform());
        if (window.Capacitor.isNativePlatform()) {
            console.log('Applying safe area adjustments for Capacitor in Native Platform...');
            SafeArea.getSafeAreaInsets().then(({ insets }) => {
                console.log('insets bottom:', insets.bottom);
                setSafeAreaInsets(insets);
            });
        } else {
            // For the web, you can apply standard CSS or manual adjustments
            setSafeAreaInsets({ top: 0, bottom: 0, left: 0, right: 0 });
        }
    }, []);

    const detectIosBottomPadding = (isKeyboardVisible) => {
        const isIos = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isInStandaloneMode = () => window.navigator.standalone === true;

        if (isIos() && isInStandaloneMode() && !isKeyboardVisible) {
            setIsStandaloneIOS(true);
        } else {
            setIsStandaloneIOS(false);
        }
    }

    const renderFooter = () => {
        switch (footerType) {
            case footerTypes.STANDARD:
                return <FooterStandard setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />;
            case footerTypes.CONSULTATION:
                return <FooterConsultation />;
            default:
                return <FooterStandard setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />;
        }
    }

    return (
        footerVisibility && (
            <Slide direction="up" in={footerVisibility} timeout={300} unmountOnExit>
                <Box sx={{
                    position: 'relative', maxWidth: 'md', bottom: 0, width: '100%',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                    backgroundColor: 'white',
                    paddingBottom: safeAreaInsets.bottom,
                    zIndex: 1000,
                }}>
                    {renderFooter()}
                </Box>
            </Slide>
        )
    );
};

export default Footer;
