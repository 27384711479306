import React from 'react';
import { AppBar, Box, Card, CardActionArea, CardContent, TextField, Avatar, Typography } from '@mui/material';
import serviceHA from '../img/services/service-health.webp';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from '../components/AuthContext';

const HeaderOffice = ({ headerRef, handleOfficeSelect, onSearchChange }) => {
    const { userProfile } = useAuth();
    const userPhoto = userProfile?.picture || null;

    return (
        <AppBar ref={headerRef} position="relative" sx={{ maxWidth: 'md', left: 0, backgroundColor: 'white', color: 'black', py: 1, px: 2, justifyContent: 'center' }}>
            <Card sx={{ position: 'relative', overflow: 'hidden', width: '100%', p: 0, mb: 1, borderRadius: '11px' }} elevation={0}>
                <CardActionArea onClick={handleOfficeSelect}>
                    <CardContent sx={{ p: 0 }}>
                        <Box sx={{
                            backgroundImage: `url(${serviceHA})`, backgroundSize: 'cover', backgroundPosition: 'center',
                            width: '100%', aspectRatio: '20/9', borderRadius: '11px',
                            justifyContent: 'left',
                            position: 'relative', overflow: 'hidden',
                        }}>
                            <Box sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                background: 'linear-gradient(to right, rgba(255,255,255,0.7), rgba(255,255,255,0))',
                            }} />
                            <Avatar sx={{ width: 71, height: 71, m: 2 }} src={userPhoto} alt="User Photo" />
                            <Typography sx={{ pb: 2, px: 2, width: '20%', textAlign: 'left', position: 'relative', }} variant="h3">
                                Health Advisor
                            </Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search"
                    onChange={onSearchChange}
                    InputProps={{
                        startAdornment: (
                            <SearchIcon sx={{ mr: 1 }} />
                        ),
                        sx: { borderRadius: '100px' },
                    }}
                    sx={{ width: '100%' }}
                />
            </Box>
        </AppBar >
    );
};

export default HeaderOffice;
