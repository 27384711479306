import api from './API.js';
import { Microphone } from '@mozartec/capacitor-microphone';

const checkPermissions = async () => {
    const isNative = window.Capacitor.isNativePlatform();
    if (!isNative) return true;
    try {
        const permissionStatus = await Microphone.requestPermissions();
        return (permissionStatus.microphone === 'granted');
    } catch (error) {
        console.error('Error requesting microphone permission:', error);
        return false;
    }
};

class TranscriptionService {
    constructor(language, setTranscriptionChunks, setIsRecording, silenceDetecting = false) {
        this.setTranscriptionChunks = setTranscriptionChunks;
        this.setIsRecording = setIsRecording;
        this.socket = null;
        this.mediaRecorder = null;
        this.isRecording = false;
        this.lastChunkReceivedAt = 0;
        this.lastFinalChunkReceivedAt = 0;
        this.silenceDetectionInterval = null;
        this.silenceDetecting = silenceDetecting;
        this.language = language;
    }

    async getMicrophoneStream() {
        try {
            console.log('Requesting microphone access...');
            if (!await checkPermissions()) throw new Error('Microphone access denied');
            console.log('Microphone access granted');
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            return stream;
        } catch (err) {
            console.error('Error accessing the microphone', err);
            throw err; // Propagate the error
        }
    }

    async startRecording() {
        if (this.isRecording) {
            this.stopRecording();
            return;
        }

        try {
            const stream = await this.getMicrophoneStream();
            this.mediaRecorder = new MediaRecorder(stream);
            this.socket = await api.apiGetWebSocketTranscribe();

            this.setupRecorder();
            this.setupWebSocket();

            this.lastChunkReceivedAt = 0; // Initialize when the recording starts
            this.lastFinalChunkReceivedAt = 0; // No final chunk received yet, so set to 0

            this.isRecording = true;
            this.setIsRecording(this.isRecording);
            this.mediaRecorder.start(250); // Start recording, generate data chunks every 250ms
            if (this.silenceDetecting) this.detectSilence();
        } catch (error) {
            this.stopRecording();
            console.error('Error starting recording', error);
        }
    }

    setupRecorder() {
        this.mediaRecorder.ondataavailable = event => {
            if (event.data.size > 0 && this.socket.readyState === WebSocket.OPEN) {
                //console.log('Sending chunk:', event.data);
                this.socket.send(event.data);
            }
        };
    }

    detectSilence() {
        this.silenceDetectionInterval = setInterval(() => {
            const now = Date.now();
            //console.log("now: ", now, "lastChunkReceivedAt: ", this.lastChunkReceivedAt, "lastFinalChunkReceivedAt: ", this.lastFinalChunkReceivedAt)
            if (this.lastFinalChunkReceivedAt > 0 &&
                (now - this.lastFinalChunkReceivedAt) > 1000) {
                //console.log('Silence detected by final chunk, stopping recording.');
                this.stopRecording();
            }
            if (this.lastChunkReceivedAt > 0 &&
                (now - this.lastChunkReceivedAt) > 1000) {
                //console.log('Silence detected - no final chunk received, stopping recording.');
                this.stopRecording();
            }

        }, 500);
    }

    setupWebSocket() {
        if (!this.socket) {
            return;
        }
        const initMessage = JSON.stringify({
            type: 'init',
            langCode: this.language,
        });
        //console.log('Init message:', initMessage);
        this.socket.send(initMessage);
        this.socket.onopen = () => {
            console.log('WebSocket connection established');
            // Send the language code to the server
        };
        this.socket.onmessage = this.handleWebSocketMessage.bind(this);
        this.socket.onerror = error => {
            this.stopRecording();
            console.error('WebSocket error:', error);
        };
        this.socket.onclose = () => {
            this.stopRecording();
            console.log('WebSocket connection closed');
        };
    }

    handleWebSocketMessage(event) {
        const data = JSON.parse(event.data);
        if (data.results && data.results.length > 0) {

            const now = Date.now();
            this.lastChunkReceivedAt = now; // Update whenever any chunk is received

            const requestId = data.requestId
            const isFinal = data.results[0].isFinal;

            if (isFinal) {
                this.lastFinalChunkReceivedAt = Date.now();
            }

            //console.log('Received chunk:', data.results[0].alternatives[0].transcript);
            //console.log('Request ID:', requestId, 'isFinal:', isFinal);

            this.setTranscriptionChunks(prevChunks => {
                //console.log('Prev Chunks:', prevChunks);

                const existingIndex = prevChunks.findIndex(chunk => chunk.requestId === requestId);

                const newChunk = {
                    requestId: (isFinal) ? 'Final' : data.requestId,
                    transcript: data.results[0].alternatives[0].transcript,
                    isFinal: data.results[0].isFinal
                };

                //console.log('New Chunk:', newChunk);
                if (existingIndex !== -1) {
                    //console.log('Replacing. Existing Index:', existingIndex);
                    // Update existing chunk if it's already in the array
                    const updatedChunks = [...prevChunks];

                    updatedChunks[existingIndex] = newChunk;
                    return updatedChunks;
                } else {
                    // Append new chunk if it's not in the array
                    //console.log('Appending. Existing Index:', existingIndex);
                    return [...prevChunks, newChunk];
                }
            });

        }
    }

    stopRecording() {
        if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
            this.mediaRecorder.stop();
            this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }

        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.close();
        }
        clearInterval(this.silenceDetectionInterval); // Clear interval on stop
        this.isRecording = false;
        this.setIsRecording(this.isRecording);
    }


    async transcribeAudioFile(file) {
        try {
            this.socket = await api.apiGetWebSocketTranscribe();
            this.setupWebSocket();

            const audioContext = new AudioContext();
            const reader = new FileReader();

            reader.onload = async (event) => {
                const arrayBuffer = event.target.result;
                const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
                const bufferSource = audioContext.createBufferSource();
                bufferSource.buffer = audioBuffer;

                const dest = audioContext.createMediaStreamDestination();
                bufferSource.connect(dest);
                bufferSource.connect(audioContext.destination);
                bufferSource.start();

                this.mediaRecorder = new MediaRecorder(dest.stream);
                this.setupRecorder();

                this.mediaRecorder.start(250);
                bufferSource.onended = () => {
                    this.mediaRecorder.stop();
                };
            };

            reader.readAsArrayBuffer(file);
        } catch (error) {
            console.error('Error transcribing audio file', error);
        }
    }


}

export default TranscriptionService;