import React from 'react';
import { Typography, Box, Grid, Collapse } from '@mui/material';
import ConsultationCard from './ConsultationCard';

const ConsultationGroup = ({ title, consultations, userPhoto, handleCardClick, expanded, onExpand, isDeleted }) => {

    return (
        <Box sx={{ width: '100%', mt: 0, pb: 2 }}>
            <Typography variant="body1" color="black" onClick={onExpand} sx={{ cursor: 'pointer', px: { xs: 2, md: 3 }, mb: 2 }}>
                {title} / {consultations.length} &nbsp; {expanded ? '▽' : '▷'}
            </Typography>
            <Collapse in={expanded}>
                <Grid container rowSpacing={1} spacing={2} sx={{ ml: 0, pl: { md: 1 }, pr: { xs: 2, md: 3 }, py: 0.5, width: '100%' }}>
                    {consultations.map((profile, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <ConsultationCard
                                content={profile}
                                userPhoto={userPhoto}
                                handleCardClick={handleCardClick}
                                isDeleted={isDeleted}
                                index={index}
                                expanded={expanded}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
        </Box>
    );
};

export default ConsultationGroup;
