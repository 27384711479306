// SearchBox.tsx
import React, { ChangeEvent } from 'react';
import { TextField, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchBoxProps {
    onSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ onSearchChange }) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onSearchChange) {
            onSearchChange(event);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center', width: '100%', mt: 1 }}>
            <TextField
                variant="outlined"
                size="small"
                placeholder="Search"
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <SearchIcon sx={{ mr: 1 }} />
                    ),
                    sx: { borderRadius: '100px' },
                }}
                sx={{ width: '100%', px: 2, mb: 1 }}
            />
        </Box>
    );
};

export default SearchBox;

